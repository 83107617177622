import React from 'react';
import Navbar from '../shared/Navbar';

const ReturnExchangePolicy = () => {
  return (
    <div>
        <Navbar />
      <h1>Return and Exchange Policy</h1>

      <h2>Money Back Guarantee</h2>
      <p>At Mystic Leaf, your satisfaction is our top priority. We stand behind our products and believe in the quality of our Kratom. If for any reason you are not completely satisfied with your purchase, we offer a refund for returned, unopened items only. To initiate a refund, please follow these steps:</p>
      <ul>
        <li>Contact our customer service team at support@mysticsleaf.ca to confirm your eligibility for a refund and to receive return instructions.</li>
        <li>Return the unopened item to the address provided by our customer service team.</li>
        <li>Upon receipt and inspection of the item, if it is in its original unopened condition, we will process your refund promptly.</li>
      </ul>
      <p>Please note, the customer is responsible for all return shipping costs, which are non-refundable.</p>

      <h2>Exchange Policy</h2>
      <p>If you wish to exchange a product, you may do so by returning the original, unopened item at your own expense. Once we receive your returned item in its original condition, you can select any other product of your choice from our available stock, and we will ship it to you at no additional cost.</p>
      
      <h3>How to Initiate an Exchange</h3>
      <p>To initiate an exchange, please follow these steps:</p>
      <ul>
        <li>Contact our support team at support@mysticsleaf.ca to confirm the exchange details and receive shipping instructions.</li>
        <li>Send the unopened item back to us at the address provided by our customer service team. Please note that you will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.</li>
        <li>Once we receive the item and confirm it is in its original condition, we will process your exchange and send out your new product immediately.</li>
      </ul>

      <h2>Additional Information</h2>
      <p>Exchanges are dependent on existing stock. If you choose a replacement item of lesser value, the difference will be refunded to you. If the replacement item is of greater value, you will need to pay the difference.</p>

      <p>We aim to make this process as smooth and hassle-free as possible. If you have any questions about our return or exchange policy, please don’t hesitate to contact us at support@mysticsleaf.ca.</p>
    </div>
  );
}

export default ReturnExchangePolicy;
