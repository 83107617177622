import React, { useState, useEffect } from "react";
import Navbar from "../shared/Navbar";
import { Outlet, Link, useNavigate, useMatch, useLocation } from "react-router-dom";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Box, Grid, Typography, Button, Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Divider } from "@mui/material";
import firestoreService from "../../services/firestoreServices";
import { toast } from "react-toastify";
import FirebaseLoginComponent from "../../services/FirebaseLoginComponent";


export default function CartPage() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  useEffect(() => {

    const uid = sessionStorage.getItem("uid");
    const isUserSignedIn = sessionStorage.getItem("isUserSignedIn");
    if (isUserSignedIn === 'true') {
      setIsUserSignedIn(true);
    } else {
      setIsUserSignedIn(false);
    }
    firestoreService.getCart(uid).then((tempItems) => {
      if (!tempItems) {
        return;
      }
      // sessionStorage.setItem("cartItems", JSON.stringify(tempItems));
      setCartItems(tempItems);
      setSubtotal(tempItems.reduce((acc, item) => acc + item.price * item.quantity, 0));

    });
  }, []);

  function removeCartItem(itemToRemove) {
    const uid = sessionStorage.getItem("uid");
    firestoreService.removeFromCart(itemToRemove, uid)
      .then((updatedCart) => {
        const newCartItems = updatedCart.map((cartItem) => ({
          price: cartItem.variant.price,
          name: `${cartItem.product.title} - ${cartItem.variant.title}`,
          quantity: cartItem.quantity,
          productImage: cartItem.product.productImages[0],
          slug: cartItem.product.slug,
          sku: cartItem.variant.sku,
        }));
        setCartItems(newCartItems);
        setSubtotal(newCartItems.reduce((acc, item) => acc + item.price * item.quantity, 0));
        toast.success("Item Removed From Cart");
      })
      .catch(error => {
        console.error("Error removing item from cart: ", error);
        toast.error("Error removing item from cart");
      });
  }
  
  

  return (
    <Box style={{ marginBottom: '100px' }}>
      <Navbar />
      <Box bgcolor="#2a5934" display="flex" flexDirection="column" alignItems="center" py={4} boxShadow="0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)">
        <ShoppingCartIcon sx={{ color: "#e0e0e0", fontSize: "70px" }} />
        <Typography variant="h4" color="#e0e0e0" fontWeight="500" mt={2}>Your Basket</Typography>
      </Box>

      <Box px={2} py={4} maxWidth={1200} margin="auto">
        {cartItems?.length > 0 ? (<>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><Typography variant="subtitle1" fontWeight="500">Product</Typography></TableCell>
                  <TableCell align="right"><Typography variant="subtitle1" fontWeight="500">Price</Typography></TableCell>
                  <TableCell align="right"><Typography variant="subtitle1" fontWeight="500">Quantity</Typography></TableCell>
                  <TableCell align="right"><Typography variant="subtitle1" fontWeight="500">Total</Typography></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <img src={row.productImage ? row?.productImage : ""} alt={row.name} style={{ width: "170px" }} />
                    </TableCell>
                    <TableCell>
                      <Link to={`/shop/${row.slug}`}><Typography variant="body2">{row.name}</Typography></Link>
                    </TableCell>
                    <TableCell align="right"><Typography variant="body2">${row.price.toFixed(2)}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="body2">{row.quantity}</Typography></TableCell>
                    <TableCell align="right"><Typography variant="body2">${(row.price * row.quantity).toFixed(2)}</Typography></TableCell>
                    <TableCell>
                      <Button variant="outlined" color="secondary" size="small" onClick={() => removeCartItem(row)}>Remove</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="space-between" mt={5} alignItems="center">
            <Typography variant="h5" fontWeight="500">Subtotal:</Typography>
            <Typography variant="h5" fontWeight="bold">${subtotal.toFixed(2)}</Typography>
          </Box>
          <Box mt={3} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" size="large" fullWidth={false} onClick={() => {

              if (isUserSignedIn) {
                
                navigate("/checkout")
              } else {
                setShowModal(true)

              }

            }}>Proceed to Checkout</Button>
          </Box></>) : (
          // Display when cart is empty
          <Box display="flex" flexDirection="column" alignItems="center" mt={10} mb={20}>
            <Typography variant="subtitle1" color="textSecondary">
              Your cart is currently empty.
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={() => navigate("/shop")}>
              Start Shopping
            </Button>
          </Box>
        )}
      </Box>
      {showModal && <>
        <FirebaseLoginComponent show={showModal} onHide={() => {
          setShowModal(false);
          navigate("/checkout")
        }} />
      </>}
      {/* <Footer /> */}
    </Box>
  );
}


