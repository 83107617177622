import { useState, useEffect } from "react";
import Navbar from "../shared/Navbar";

import { useForm, Controller } from "react-hook-form";
import { Grid, TextField, Button, Container, Checkbox, Typography } from "@mui/material";
import Input from "@mui/material/Input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from 'react-toastify';
import { Outlet, Link, useNavigate, useMatch, useLocation, useParams } from "react-router-dom";
import FirebaseLoginComponent from "../../services/FirebaseLoginComponent";
import moment from "moment";
import { logCustomEvent } from "../../services/analyticsLogger";


import firestoreService from "../../services/firestoreServices";

export default function SampleFormPage() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userOrderedSample, setUserOrderedSample] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      select: {},
      country: "Canada",
    },
  });

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    logCustomEvent('screen_view', {
      firebase_screen: 'sample_form_page',
      uid: uid,

    });

    firestoreService.getUser(uid).then((user) => {
      setUserOrderedSample(user.hasOrderedSample);
      setUser(user);

      if (user.userData) {
        for (const key in user.userData) {
          if (user.userData.hasOwnProperty(key)) {
            setValue(key, user.userData[key] || "");
          }
        }
      }
    });
  }, []);

  const cleanObject = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object")
        cleanObject(obj[key]); // Recurse into objects
      else if (obj[key] === undefined) delete obj[key]; // Delete undefined properties
    });
    return obj;
  };

  const onSubmit = (data) => {
    const uid = sessionStorage.getItem("uid");
    // firestoreService.createSampleOrder(data,uid).then(()=>{
    // sessionStorage.setItem('askedForSample',true)
    // toast.success(' Your Sample Will Be Shipped!')
    //     navigate("/home");
    // })

    const cleanedData = cleanObject({
      uid,
      ...data,
      paid: true,
      cartItems: [{
        name: "Super Green Meng Da - 5g",

        price: 65,

        productImage: "https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/assets%2FproductImage1.jpg?alt=media&token=7e32aa7c-b38d-4f16-85d6-4d91b3b20c74",

        quantity: 1,

        slug: "super-green-meng-da"
      }],
      status: "pending",
      createdAt: moment().format('MMMM Do h:mm A'),
      updatedLast: moment().utc().unix(),
      unixTime: moment().utc().unix(),
      grandTotal: parseFloat(0.00),
      tax: parseFloat(0.00),
      shippingCharge: 0.00,
      orderType: 'sampleOrder',
      shippingMethod: "freeShipping"

    })
    firestoreService
      .createOrder(uid, cleanedData)
      .then((orderId) => {

        sessionStorage.setItem('askedForSample', true)
        toast.success(' Your Sample Will Be Shipped!')
        navigate("/home");
        // setHandleModalOpen(true);
        // setOrderId(orderId);
        firestoreService.updateUserData(uid, { hasOrderedSample: true });
      })
      .then(() => {

      });
  };

  useEffect(() => {
    const isUserSignedIn = sessionStorage.getItem("isUserSignedIn");
    if (isUserSignedIn === 'true') {
      setIsUserSignedIn(true);


    } else {
      setIsUserSignedIn(false);
      setShowModal(true)
    }
  }, [])
  const handleModal = () => {
    setShowModal(false)
    toast.error('You need to be signed in to place an order')

    navigate("/home")
  }
  return (<>
    <div style={{ minHeight: '100vh' }}>
      <Navbar />

      {userOrderedSample ? (
        <Container
          maxWidth="md"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Sample Already Ordered
          </Typography>
          <Typography variant="body1" align="center">
            We're thrilled to see your enthusiasm for Mystics Leaf! 🌿 However, our mystical policy allows us only to send a single sample per person. This ensures that all those seeking the magical experience of our leaves get a fair chance. 🍃✨
          </Typography>
          <Typography variant="body1" align="center" style={{ marginTop: "20px" }}>
            But don't let this stop you from exploring further - our full range of products awaits your discerning taste. Feel free to peruse and purchase the potions that pique your interest. Your journey with Mystics Leaf is just beginning! 🧙‍♂️
          </Typography>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button variant="contained" color="primary" style={{ backgroundColor: '#4caf50' }} onClick={() => { navigate("/shop/" + "super-green-meng-da"); }}>
              Shop Now
            </Button>
          </div>
        </Container>
      ) : (
        <Container
          maxWidth="md" style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Order Sample
          </Typography>
          <Typography variant="body1" align="center">
            Our mystics beileve you gotta try it before you buy it, if this is the
            first time you experince the leaf let us give you a sample so you
            don't need to take our word for it
          </Typography>
          <Typography variant="body1" align="center" style={{ marginBottom: "20px" }}>
            Fill out the form Below and get your sample shipped for free directrly
            to your door steps
          </Typography>
          <Card raised style={{ padding: "20px" }}>
            <Card.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={user?.userData?.email}
                      rules={{ required: "Email is required" }}
                      render={({ field, fieldState }) => (
                        <TextField
                          required
                          {...field}
                          label="Email"
                          variant="outlined"
                          fullWidth
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                        />
                      )}
                    />

                  </Grid>

                  <Grid item xs={12}>
                    <h3>Shipping Address</h3>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth={true}
                          label="Country"
                          disabled
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth={true}
                          label="First Name"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth={true}
                          label="Last Name"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="address1"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth={true}
                          label="Address"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="address2"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth={true}
                          label="Apartment,suite,etc. (optional)"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth={true}
                          label="City"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="province"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth={true}
                          label="Province"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="postalCode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          required
                          fullWidth={true}
                          label="Postal Code"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          
                          fullWidth={true}
                          label="Phone Number"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <FormControl required error={!!errors.acceptConditions}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register("acceptConditions", {
                            required:
                              "You must accept the conditions to place an order",
                          })}
                        />
                      }
                      label={
                        <Typography variant="body1" color="error">
                          I understand this is not meant to be consumed by humans.
                          And for botanical purposes only.
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginTop: "10px" }}
                >
                  Place Order
                </Button>
              </form>
            </Card.Body>
          </Card>
        </Container>
      )}


      {!isUserSignedIn && showModal && <>
        <FirebaseLoginComponent show={showModal} onHide={() => { handleModal() }} />
      </>}
    </div>

  </>
  );
}
