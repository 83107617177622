import React, { useState, useEffect } from "react";
import { useForm, Controller, set } from "react-hook-form";
import { Grid, TextField, Button, Container, Typography } from "@mui/material";
import Orders from "./Orders";
import "./Account.css";
import Navbar from "../shared/Navbar";
import { Outlet, Link, useNavigate, useMatch, useLocation } from "react-router-dom";
import { getAuth, signInAnonymously, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../services/services";
import firestoreService from "../../services/firestoreServices";
import FirebaseLoginComponent from "../../services/FirebaseLoginComponent";
import { ToastContainer, toast } from 'react-toastify';
import { logCustomEvent } from "../../services/analyticsLogger";
import Rewards from "./Rewards/Rewards";
// import FirebaseLoginComponent from "./FirebaseLoginComponent";

function AccountPage() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("profile");
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: sessionStorage.getItem("email") || "",
    address1: "",
    address2: "",
    city: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
  });
  const [userInfo, setUserInfo] = useState({})
  const { control, handleSubmit, setValue, getValues, reset } = useForm();
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    logCustomEvent('screen_view', {
      firebase_screen: 'AccountPage',
      uid: uid,
      isSignedIn: isSignedIn

    });
  }, [isSignedIn]);

  //   const { control, handleSubmit, setValue, getValues } = useForm({
  //     defaultValues: {
  //       firstName: userData.firstName,
  //       lastName: userData.lastName,
  //       email: userData.email,
  //       address1: userData.address1,
  //       address2: userData.address2,
  //       city: userData.city,
  //       province: userData.province,
  //       postalCode: userData.postalCode,
  //       phoneNumber: userData.phoneNumber,
  //       country: "Canada",
  //     },
  //   });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // // User is signed in, see docs for a list of available properties
        // // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        sessionStorage.setItem("uid", uid);
        sessionStorage.setItem("username", user.displayName || "");
        // setUserId(uid);

        if (user.isAnonymous) {
          setIsSignedIn(false)
          setShowModal(true)
        } else {
          setIsSignedIn(true)
          setShowModal(false)
        }

        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);



  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    const email = sessionStorage.getItem("email");
    firestoreService.getUser(uid).then((tempItems) => {
      if (tempItems) {
        setUserInfo(tempItems)
        setOrders(tempItems.orders || []);
        //   setUserData(prevState => ({ ...prevState, ...tempItems }));
        setUserData(tempItems.userData || {});
        reset({
          firstName: tempItems.userData?.firstName || "",
          lastName: tempItems.userData?.lastName || "",
          email: tempItems.userData?.email || email || '',
          address1: tempItems.userData?.address1 || "",
          address2: tempItems.userData?.address2 || "",
          city: tempItems.userData?.city || "",
          province: tempItems.userData?.province || "",
          postalCode: tempItems.userData?.postalCode || "",
          phoneNumber: tempItems.userData?.phoneNumber || "",
          country: "Canada",

          // ... (and so on for all other fields)
        });


        for (const key in tempItems) {
          if (tempItems.hasOwnProperty(key)) {
            setValue(key, tempItems[key] || "");
          }
        }
      }
    });
  }, [setValue]);

  const handleModal = () => {
    setShowModal(false)
    toast.error('Please sign in to continue to your account')
    navigate("/home")
  }

  const onSubmit = (data) => {
    const uid = sessionStorage.getItem("uid");
    firestoreService
      .updateUser(uid, data)
      .then(() => {
        setUserData(data);
        alert("Profile Updated Successfully");

        logCustomEvent('screen_view', {
          firebase_screen: 'AccountPage',
          action: 'updatedUser',
          userData: data

        });

      })
      .catch((error) => {
        alert("Error updating profile: " + error.message);
      });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Navbar />
      {isSignedIn && <div className="account-container" style={{ flex: "1" }}>
        <div className="account-info">
          <h2>Your Account</h2>
          <p>
            Name: {userData.firstName} {userData.lastName}
          </p>
          <p>Email: {userData.email || sessionStorage.getItem("email")}</p>
          <Button variant="contained" color="primary" onClick={() => {
            signOut(auth).then(() => {
              // Sign-out successful.
              navigate("/home")
            }).catch((error) => {
              // An error happened.
            });
          }
          }>
            Sign Out
          </Button>
        </div>
        <div className="account-tabs">
          <h3>Manage Account</h3>
          <ul>
            <li
              className={selectedTab === "profile" ? "active" : ""}
              onClick={() => setSelectedTab("profile")}
            >
              Profile
            </li>
            <li
              className={selectedTab === "orders" ? "active" : ""}
              onClick={() => setSelectedTab("orders")}
            >
              Orders
            </li>
            <li
            className={selectedTab === "rewards" ? "active" : ""}
            onClick={() => setSelectedTab("rewards")}
          >
            Rewards
          </li>
          </ul>
        </div>
        <div className="account-details">
          {selectedTab === "profile" && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h3>Contact</h3>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={userData.email || ""}

                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="Email"
                        variant="standard"
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <h3>Shipping Address</h3>
                  <Controller
                    defaultValue={"Canada"}
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth={true}
                        label="Country"
                        disabled
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    defaultValue={userData.firstName || ""}
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="First Name"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    defaultValue={userData.lastName || ""}
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="Last Name"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    defaultValue={userData.address1 || ""}
                    name="address1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="Address"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    defaultValue={userData.address2 || ""}
                    name="address2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth={true}
                        label="Apartment,suite,etc. (optional)"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    defaultValue={userData.city || ""}
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="City"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    defaultValue={userData.province || ""}
                    name="province"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="Province"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    defaultValue={userData.postalCode || ""}
                    name="postalCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="Postal Code"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    defaultValue={userData.phoneNumber || ""}
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth={true}
                        label="Phone Number"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                {/* </Grid> */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {selectedTab === "orders" && <Orders accountOrders={orders} />}
          {selectedTab === "rewards" && <Rewards userData={userInfo} />}
        </div>
      </div>}
      {!isSignedIn && showModal && <>
        <FirebaseLoginComponent show={showModal} onHide={() => { handleModal() }} />
      </>}
    
    </div>
  );
}

export default AccountPage;
