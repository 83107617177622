import React from 'react';
import Navbar from '../shared/Navbar';

const TermsOfPurchase = () => {
  return (
    <div>
        <Navbar />
      <h1>Terms of Purchase</h1>

      <h2>Order Acceptance and Billing</h2>
      <p>All purchases through our website are subject to product availability. We may, in our sole discretion, limit or cancel the quantities offered on our website or limit the sales of our products or services to any person, household, geographic region, or jurisdiction.</p>
      <p>Prices for our products are subject to change, without notice. Unless otherwise indicated, prices displayed on our website are quoted in Canadian dollars and do not include shipping costs or applicable taxes, which will be added to your total invoice price.</p>
      <p>You agree to provide current, complete, and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>

      <h2>Payment Terms</h2>
      <p>Terms of payment are within our sole discretion and, unless otherwise agreed by us in writing, shall be as follows:</p>
      <ul>
        <li>All payments must be made by the methods we specify on the website.</li>
        <li>You represent and warrant that (i) the credit card information you supply to us is true, correct, and complete, (ii) you are duly authorized to use such credit card for the purchase, (iii) charges incurred by you will be honored by your credit card company, and (iv) you will pay charges incurred by you at the posted prices, including shipping and handling charges and all applicable taxes, if any.</li>
      </ul>

      <h2>Legal Use of Products</h2>
      <p>The products available for purchase on our website are intended for legal use and may not be used in any way that is illegal or unauthorized. You agree to comply with all applicable laws, rules, and regulations in connection with your use of the products. Mystic Leaf assumes no responsibility or liability for any illegal or unauthorized use of our products.</p>

      <h2>Risk of Loss</h2>
      <p>All items purchased from our website are made pursuant to a shipment contract. This means that the risk of loss and title for such items pass to you upon our delivery to the carrier.</p>

      <h2>Changes to Terms</h2>
      <p>We reserve the right, in our sole discretion, to update, change or replace any part of these Terms of Purchase by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Purchase constitutes acceptance of those changes.</p>

      <h2>Contact Information</h2>
      <p>Questions about the Terms of Purchase should be sent to us at support@mysticsleaf.ca.</p>
    </div>
  );
}

export default TermsOfPurchase;
