import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import "./MyRewards.css";
import { logCustomEvent } from '../../../services/analyticsLogger';
import firestoreService from '../../../services/firestoreServices';
import { Button } from '@mui/material';
import moment from 'moment';

const MyRewards = ({ userData }) => {
  const { referralRewardsEarned, referralCode, pointsEarned, availableDiscounts } = userData;
  const [discounts, setDiscounts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    const uid = sessionStorage.getItem('uid');
    logCustomEvent('screen_view', {
      firebase_screen: 'my_rewards',
      uid: uid,
    });
  
    if (availableDiscounts && availableDiscounts.length > 0) {
      Promise.all(availableDiscounts.map(code => firestoreService.getDiscountCode(code)))
        .then(discountDataArray => {
          // Filter out any null or undefined discount data
          const validDiscounts = discountDataArray.filter(Boolean);
          setDiscounts(validDiscounts);
          setExpandedDescriptions(validDiscounts.reduce((acc, discount) => ({
            ...acc,
            [discount.id]: false
          }), {}));
        })
        .catch(error => {
          console.error("Failed to fetch discounts:", error);
          // Handle the error appropriately
        });
    }
  }, [availableDiscounts]); // assuming `availableDiscounts` is a state or prop
  

  const toggleFullDescription = (id) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  return (
    <div className="my-rewards-container">
      <div className="my-rewards-header">
        <Typography variant="h4" component="h2" gutterBottom>
          My Rewards Overview
        </Typography>
      </div>
      <div className="rewards-cards-container">
        <Card className="reward-card">
          <CardContent className="reward-card-content">
            <Typography color="textSecondary">
              Referral Rewards Earned
            </Typography>
            <Typography variant="h5">
              {referralRewardsEarned || 0}
            </Typography>
          </CardContent>
        </Card>

        {/* <Card className="reward-card">
          <CardContent className="reward-card-content">
            <Typography color="textSecondary">
              Points Earned
            </Typography>
            <Typography variant="h5">
              {pointsEarned || 0}
            </Typography>
          </CardContent>
        </Card> */}

        <Card className="reward-card">
          <CardContent className="reward-card-content">
            <Typography color="textSecondary" gutterBottom>
              Available Discounts
            </Typography>
            {discounts.length > 0 ? (
              discounts.map((discount, index) => (
                <div key={index} className="discount-detail">
                  <Typography variant="h6" component="p">
                    {discount.value}% off - Code: {discount.code}
                  </Typography>
                  <Typography color="textSecondary" component="p">
                    {discount.description.length > 100
                      ? expandedDescriptions[discount.id]
                        ? discount.description
                        : `${discount.description.substring(0, 100)}...`
                      : discount.description}
                    {discount.description.length > 100 && (
                      <Button onClick={() => toggleFullDescription(discount.id)}>
                        {expandedDescriptions[discount.id] ? 'Less' : 'More'}
                      </Button>
                    )}
                  </Typography>
                  <Typography color="textSecondary" component="p">
                    Valid from: {moment.unix(discount.validFrom.seconds).format('MMMM Do YYYY')}
                    {discount.validUntil === 0 ? ' onwards' : ` until ${moment.unix(discount.validUntil).format('MMMM Do YYYY')}`}
                  </Typography>
                  {discount.minimumPurchase > 0 && (
                    <Typography color="textSecondary" component="p">
                      Minimum purchase: ${discount.minimumPurchase.toFixed(2)}
                    </Typography>
                  )}
                </div>
              ))
            ) : (
              <Typography component="p">No Discounts</Typography>
            )}
          </CardContent>
        </Card>
        {/* Add more cards for other reward types as needed */}
      </div>
    </div>
  );
};

export default MyRewards;
