import logo from "./logo.svg";
// import './App.css';
import {
  Routes,
  Route,
  Outlet,
  Link,
  Router,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Footer from "./components/shared/Footer";
import HomePage from "./components/homepage/Homepage";
import AccountPage from "./components/account/AccountPage";
import CartPage from "./components/cart/CartPage";
import ProductsPage from "./components/products/ProductsPage";
import ProductPage from "./components/products/ProductPage";
import CheckoutPage from "./components/checkout/CheckoutPage";
import FaqPage from "./components/faq/FaqPage";
import PrivacyPolicy from "./components/faq/PrivacyPolicy.js";
import TermsOfPurchase from "./components/faq/TermsOfPurchase";
import ReturnExchangePolicy from "./components/faq/ReturnExchangePolicy";
import SampleFormPage from "./components/sampleForm/SampleFormPage";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { auth, functions, analytics } from "./services/services";
import firestoreService from "./services/firestoreServices";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button } from "@mui/material";
import { logCustomEvent } from "./services/analyticsLogger";
// import AddBlog from './components/blogs/AddBlog';
import Blogs from "./components/blogs/Blogs";
import BlogPost from "./components/blogs/BlogPost";
import PopupBanner from "./components/shared/PopupBanner";

function App() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [userIsAnonymous, setUserIsAnonymous] = useState(false);
  const [isAnonymousSession, setIsAnonymousSession] = useState(false);

  // Use this function on the landing page or any other relevant page
  function captureReferral() {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get("ref");
    if (referralCode) {
      sessionStorage.setItem("referralCode", referralCode);
    }
  }

  useEffect(() => {
    logCustomEvent("screen_view", {
      firebase_screen: "app",
      uid: sessionStorage.getItem("uid"),
    });
    captureReferral();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;

        sessionStorage.setItem("uid", uid);
        sessionStorage.setItem("username", user.displayName || "");
        sessionStorage.setItem("email", user.email || "");

        if (!user.isAnonymous) {
          setIsAnonymousSession(false);
          sessionStorage.setItem("isUserSignedIn", true);
          const referralCode = sessionStorage.getItem("referralCode"); // Assuming you stored it earlier
          const cartItems = sessionStorage.getItem("cartItems");
          const anonymousUid = sessionStorage.getItem("anonymousUid");

          // logic below updates the anonymous user's info to signed up user info linking the two accounts
          if (cartItems) {
            const cartJson = JSON.parse(cartItems);

            firestoreService.updateCart(uid, cartJson);
            // sessionStorage.removeItem("cartItems");
          }

          if (referralCode) {
            // Check if the current user document does not already have a 'referredBy'
            const userDoc = await firestoreService.getUser(uid);
            if (!userDoc.referredBy) {
              // Update the user document with the referralCode
              await firestoreService.updateUserData(uid, {
                referredBy: referralCode,
              });
              sessionStorage.removeItem("referralCode");
            }
          }
        } else {
          // session is anonymous
          setIsAnonymousSession(true);
          sessionStorage.setItem("anonymousUid", uid);
          sessionStorage.setItem("isUserSignedIn", false);
        }

        // If user is not anonymous, update state
        // if (!user.isAnonymous) {
        //   setIsAnonymousSession(false);
        // } else {
        //   setIsAnonymousSession(true);
        // }

        // ...
      } else {
        // User is signed out
        // ...
        sessionStorage.setItem("isUserSignedIn", false);

        if (!isAnonymousSession) {
          const userAgent = navigator.userAgent.toLowerCase();
          if (!userAgent.includes("googlebot")) {
            // Negation added here
            signInAnonymously(auth)
              .then(() => {
                sessionStorage.setItem("isUserSignedIn", false);
                setIsAnonymousSession(true); // Update state to reflect anonymous session
                // Signed in..
              })
              .catch((error) => {
                sessionStorage.setItem("isUserSignedIn", false);
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
              });
          }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  //   const callDeleteAnonymousUsersFunction = () => {
  // console.log('callDeleteAnonymousUsersFunction')
  //     const deleteAnonymousUsers = httpsCallable(functions, 'deleteAnonymousUsers');

  //     deleteAnonymousUsers()
  //       .then((result) => {
  //         console.log('we removed YAAA',result.data.message);
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error.message);
  //       });
  //   };

  return (
    <>
      <div className="App">
        <main className="main-content">
          
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route path="*" element={<HomePage />} />
            </Route>
            <Route path="account" element={<AccountPage />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="shop" element={<ProductsPage />} />
            <Route path="shop/:productId" element={<ProductPage />} />
            <Route path="checkout" element={<CheckoutPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="sampleForm" element={<SampleFormPage />} />
            {/* <Route path="addBlog" element={<AddBlog />} /> */}
            <Route path="blogs" element={<Blogs />} />
            <Route path="blog/:slug" element={<BlogPost />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-purchase" element={<TermsOfPurchase />} />
            <Route path="return-exchange-policy" element={<ReturnExchangePolicy />} />

          </Routes>
        </main>
       

        <ToastContainer />
        <PopupBanner
          title="Special Offer"
          message="Get 10% off on your first order!"
          actionText="Learn More"
          // onActionClick={handleBannerActionClick}
        /> 
      </div>{" "}<Footer />
    </>
  );
}

export default App;
