import { useState, useEffect } from "react";
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";
import { useForm, Controller, set } from "react-hook-form";
import {
  Outlet,
  Link,
  useNavigate,
  useMatch,
  useLocation,
  useParams,
} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Input from "@mui/material/Input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import firestoreService from "../../services/firestoreServices";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import ReactSelect from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { logCustomEvent } from "../../services/analyticsLogger";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/services";
import "./CheckoutPage.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function CheckoutPage() {
  const [handleModalOpen, setHandleModalOpen] = useState(false);
  const handleOpen = () => setHandleModalOpen(true);
  const handleClose = () => setHandleModalOpen(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      phoneNumber: "",
      country: "Canada",
      email: sessionStorage.getItem("email") || "",
    },
  });
  const [orderId, setOrderId] = useState("");
  const downtownTorontoFSAs = [
    "M5A",
    "M5B",
    "M5C",
    "M5E",
    "M5G",
    "M5H",
    "M5J",
    "M5K",
    "M5L",
    "M5S",
    "M5T",
    "M5V",
    "M5W",
    "M5X",

    "M6J",
    "M6K",
    "M6G",
    "M6H",
    "M6R",
    "M7A",
    "M4S",
    "M4V",
    "M4T",
    "M4W",
    "M4X",
    "M4Y",
    "M4M",
    "M4L",
    "M4K",
    "M7Y",
    "M4J",
    "M5N",
  ];
  // Function to filter pickup dates for Saturdays and Sundays
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0; // 6 = Saturday, 0 = Sunday
  };

  // Calculate the next Saturday or Sunday
  function getNextWeekend(current) {
    const nextDay = new Date(current);
    while (nextDay.getDay() !== 6 && nextDay.getDay() !== 0) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay;
  }
  const postalCodeValue = watch("postalCode") || "";
  const isDowntownToronto =
    postalCodeValue &&
    downtownTorontoFSAs.includes(postalCodeValue.slice(0, 3).toUpperCase());

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [formData, setFormData] = useState({
    address: "",
    email: sessionStorage.getItem("email") || "",
    // Add other fields as necessary
  });
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const [grandTotal, setGrandTotal] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [customCode, setCustomCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [pickupDate, setPickupDate] = useState(getNextWeekend(new Date()));
  const [loading, setLoading] = useState(false);
  const [showNoTrackingCheckbox, setShowNoTrackingCheckbox] = useState(false);
  const [pickupTime, setPickupTime] = useState(() => {
    const defaultTime = new Date();
    defaultTime.setHours(13, 0, 0, 0); // Set to 1 PM, minutes and seconds to 0
    return defaultTime;
  });

  const [total, setTotal] = useState(0); // Sample value for total amount

  const applyDiscount = (code) => {
    //   // Implement logic to apply the discount code
    //   console.log('Applying discount:', code);
    //   console.log('selectedDiscount', selectedDiscount);
    //   console.log('discounts', discounts);
    //   // Update the grand total with the discount
  };
  useEffect(() => {
    if (watch("shippingMethod") === "freeShipping") {
      setShowNoTrackingCheckbox(true);
    } else {
      setShowNoTrackingCheckbox(false);
    }
  }, [watch("shippingMethod")]); // Assuming shipmentOption is a state or prop

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    logCustomEvent("screen_view", {
      firebase_screen: "checkout",
      uid: uid,
      grandTotal: grandTotal,
      cartItems: cartItems,
    });
  }, []);

  const shippingOptions = {
    freeSameDayDowntown: {
      label: "$15 Same-Day Delivery (Toronto Downtown only, order by 2 PM)",
      price: 15,
    },
    expressDelivery: {
      label: "Express Shipping (1-3 Business Days, Tracking Included)",
      get price() {
        // This function will check if the total is above $99 and return 0 if so
        return calculateTotal() > 99 ? 0 : 10.0;
      },
    },
    freeShipping: {
      label: "Mail (7-14 Business Days, No Tracking, Free)",
      price: 0,
    },
    orderPickup: {
      label:
        "Pick-up from Downtown Toronto City Hall (Sat-Sun, 1 PM - 7 PM, order by 2 PM)",
      price: 0,
    },
  };

  const provincesAndTerritories = [
    { label: "Alberta", value: "alberta" },
    { label: "British Columbia", value: "british_columbia" },
    { label: "Manitoba", value: "manitoba" },
    { label: "New Brunswick", value: "new_brunswick" },
    { label: "Newfoundland and Labrador", value: "newfoundland_and_labrador" },
    { label: "Nova Scotia", value: "nova_scotia" },
    { label: "Ontario", value: "ontario" },
    { label: "Prince Edward Island", value: "prince_edward_island" },
    { label: "Quebec", value: "quebec" },
    { label: "Saskatchewan", value: "saskatchewan" },
    { label: "Northwest Territories", value: "northwest_territories" },
    { label: "Nunavut", value: "nunavut" },
    { label: "Yukon", value: "yukon" },
  ];

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    const isUserSignedIn = sessionStorage.getItem("isUserSignedIn");

    if (uid && isUserSignedIn) {
      firestoreService.getUser(uid).then((user) => {
        const userData = user.userData;
        if (userData) {
          Object.keys(userData).forEach((key) => {
            if (key !== "country") {
              setValue(key, userData[key]);
            }
          });
        }

        if (user?.availableDiscounts?.length) {
          // Fetch each discount details using getDiscountCode
          Promise.all(
            user.availableDiscounts.map((code) =>
              firestoreService.getDiscountCode(code)
            )
          )
            .then((discountDetails) => {
              // Filter out any null values in case some discounts weren't found
              const validDiscounts = discountDetails.filter(
                (detail) => detail !== null
              );
              const tempDiscounts = validDiscounts.map((doc) => ({
                label: doc.description,
                value: doc.id,
                ...doc,
              }));
              setDiscounts(tempDiscounts);
            })
            .catch((error) => {
              console.error("Error fetching discounts:", error);
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    if (uid) {
      const unsubscribe = firestoreService.setupRealtimeCartListener(
        uid,
        setCartItems
      );

      // Cleanup the listener when the component unmounts
      return () => unsubscribe;
    }
  }, []);

  useEffect(() => {
    if (grandTotal == 0.0 && cartItems.length > 0) {
      const tempGrandTotal = (
        calculateTotal() +
        parseFloat(calculateTax(calculateTotal())) +
        shippingCharge
      ).toFixed(2);

      const discountAmount = calculateDiscountAmount(
        tempGrandTotal,
        selectedDiscount
      );
      setGrandTotal(applyDiscountToTotal(tempGrandTotal, discountAmount));
    }
  }, [cartItems]);

  // This function calculates the discount amount based on the type and value.
  const calculateDiscountAmount = (total, discount) => {
    if (!discount) return 0.0;
    const { type, value } = discount.data;

    const discountValue = type === "percentage" ? total * (value / 100) : value;
    return discountValue;
  };

  // This function calculates the new total after applying the discount.
  const applyDiscountToTotal = (total, discountAmount) => {
    return (total - discountAmount).toFixed(2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cleanObject = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object")
        cleanObject(obj[key]); // Recurse into objects
      else if (obj[key] === undefined) delete obj[key]; // Delete undefined properties
    });
    return obj;
  };

  const onSubmit = (data) => {
    setLoading(true);
    const uid = sessionStorage.getItem("uid");
    let date = new Date(pickupDate);
    let time = new Date(pickupTime);

    const cleanedData = cleanObject({
      uid,
      ...data,
      cartItems,
      paid: false,
      status: "pending",
      createdAt: moment().format("MMMM Do h:mm A"),
      updatedLast: moment().utc().unix(),
      unixTime: moment().utc().unix(),
      grandTotal: parseFloat(grandTotal),
      tax: parseFloat(calculateTax(calculateTotal())),
      discountAmount: parseFloat(discountAmount),
      discountSelected: selectedDiscount?.data || {},
      shippingCharge: shippingCharge,
      orderType: "paidOrder",
      pickupTime: `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`,
      pickupDate: `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`,
    });

    firestoreService
      .createOrder(uid, cleanedData)
      .then((orderId) => {
        toast.success("Your Order has been placed!");

        logEvent(analytics, "purchase", {
          // Include details relevant to the purchase event
          // Replace the following with actual data
          transaction_id: orderId,
          value: grandTotal,
          currency: "CAD", // or the currency used
          items: cartItems, // this should be an array of items purchased
        });
        // navigate("/home");
        setHandleModalOpen(true);
        setOrderId(orderId);
      })
      .then(() => {});
  };

  // Calculate the total price based on cart items
  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  // Sample function to calculate tax, replace with real calculations
  const calculateTax = (total) => {
    return (total * 0.13).toFixed(2); // 13% tax
  };

  const shippingCharge = watch("shippingMethod")
    ? shippingOptions[watch("shippingMethod")].price
    : 0;

  // const grandTotal = (
  //   calculateTotal() +
  //   parseFloat(calculateTax(calculateTotal())) +
  //   shippingCharge
  // ).toFixed(2);

  useEffect(() => {
    // fix if cartItems is empty does not reset grandTotal
    if (cartItems.length === 0) return;
    const tempGrandTotal = (
      calculateTotal() +
      parseFloat(calculateTax(calculateTotal())) +
      shippingCharge
    ).toFixed(2);

    const discountAmount = calculateDiscountAmount(
      tempGrandTotal,
      selectedDiscount
    );
    setDiscountAmount(discountAmount);
    setGrandTotal(applyDiscountToTotal(tempGrandTotal, discountAmount));
  }, [shippingCharge, selectedDiscount]);

  // Whenever selectedDiscount changes, recalculate the discount amount.
  // useEffect(() => {
  //   if (selectedDiscount) {
  //     const newDiscountAmount = calculateDiscountAmount(grandTotal, selectedDiscount);
  //     console.log('what the hell man',newDiscountAmount)
  //     setDiscountAmount(newDiscountAmount);
  //   } else {
  //     setDiscountAmount(0);
  //   }
  // }, [grandTotal]);

  return (
    <div>
      <Navbar />
      <Grid container padding={"20px"}>
        <Grid item xs={12} md={6} className="order-summary">
          <Typography variant="h5" gutterBottom>
            Contact & Shipping Details
          </Typography>
          <Divider className="divider" />
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <h3>Contact</h3> */}
                <Controller
                  name="email"
                  rules={{ required: "Email is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="Email"
                      variant="standard"
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <p style={{ color: "red" }}>{errors.email.message}</p>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Shipping Address
                </Typography>
                {/* <Grid item xs={12}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      defaultValue={"Canada"}
                      label="Country"
                      disabled
                      {...field}
                    />
                  )}
                />
                </Grid> */}
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="firstName"
                  rules={{ required: "First Name is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField fullWidth={true} label="First Name" {...field} />
                  )}
                />
                {errors.firstName && (
                  <p style={{ color: "red" }}>{errors.firstName.message}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  rules={{ required: "Last Name is required" }}
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField fullWidth={true} label="Last Name" {...field} />
                  )}
                />
                {errors.lastName && (
                  <p style={{ color: "red" }}>{errors.lastName.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address1"
                  rules={{ required: "address is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField fullWidth={true} label="Address" {...field} />
                  )}
                />
                {errors.address1 && (
                  <p style={{ color: "red" }}>{errors.address1.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="Apartment,suite,etc. (optional)"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      defaultValue={"Canada"}
                      label="Country"
                      disabled
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" margin="none">
                  <InputLabel id="province-label">Province</InputLabel>
                  <Controller
                    name="province"
                    rules={{ required: "Province is required" }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        labelId="province-label"
                        id="province-select"
                        label="Province"
                        {...field}
                      >
                        {provincesAndTerritories.map((province) => (
                          <MenuItem key={province.value} value={province.label}>
                            {province.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.province && (
                  <p style={{ color: "red" }}>{errors.province.message}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="city"
                  rules={{ required: "city is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField fullWidth={true} label="City" {...field} />
                  )}
                />
                {errors.city && (
                  <p style={{ color: "red" }}>{errors.city.message}</p>
                )}
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="postalCode"
                  rules={{ required: "Postal Code is required" }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="Postal Code"
                      {...field}
                    />
                  )}
                />
                {errors.postalCode && (
                  <p style={{ color: "red" }}>{errors.postalCode.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="Phone Number"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="shippingMethod"
                  rules={{ required: "Shipping method is required" }}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Shipping Method
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        {...field}
                      >
                        {/* Same-Day Delivery Option */}
                        <FormControlLabel
                          value="freeSameDayDowntown"
                          control={<Radio />}
                          label={
                            <Tooltip
                              title={downtownTorontoFSAs.sort().join(", ")}
                              arrow
                            >
                              <span>
                                {`${
                                  shippingOptions["freeSameDayDowntown"].label
                                } 
                ${
                  !postalCodeValue
                    ? "(Enter postal code first)"
                    : !isDowntownToronto
                    ? "(Available only in Downtown Toronto)"
                    : ""
                }`}
                              </span>
                            </Tooltip>
                          }
                          disabled={!isDowntownToronto || !postalCodeValue}
                        />

                        {/* Express Delivery Option */}
                        <FormControlLabel
                          value="expressDelivery"
                          control={<Radio />}
                          label={
                            `${shippingOptions["expressDelivery"].label} ` +
                            `(${
                              shippingOptions["expressDelivery"].price === 0
                                ? "Free"
                                : `$${shippingOptions["expressDelivery"].price}`
                            })`
                          }
                        />

                        {/* Mail Option */}
                        <FormControlLabel
                          value="freeShipping"
                          control={<Radio />}
                          label={shippingOptions["freeShipping"].label}
                        />

                        {/* Pickup Option with Tooltip */}
                        <FormControlLabel
                          value="orderPickup"
                          control={<Radio />}
                          label={
                            <Tooltip
                              title={
                                <>
                                  <Typography
                                    color="inherit"
                                    variant="subtitle1"
                                  >
                                    <strong>Pickup Details:</strong>
                                  </Typography>
                                  <Typography variant="body2">
                                    Collect your order from our driver at
                                    Downtown Toronto City Hall. Pickup is
                                    available on{" "}
                                    <strong>
                                      Saturdays and Sundays from 1 PM to 7 PM
                                    </strong>
                                    . Orders must be placed before{" "}
                                    <strong style={{ color: "red" }}>
                                      2 PM
                                    </strong>{" "}
                                    for same-day pickup. Please select your
                                    preferred pickup time during checkout.
                                    <br />
                                    Our driver will contact you upon arrival to
                                    coordinate the exact location for the
                                    handoff.
                                    <br />
                                    <em>Note:</em> If you are late or fail to
                                    show up, you may be restricted from using
                                    the pickup option in the future.
                                  </Typography>
                                </>
                              }
                              arrow
                            >
                              <span>
                                {shippingOptions["orderPickup"].label}
                              </span>
                            </Tooltip>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />

                {errors.shippingMethod && (
                  <p style={{ color: "red" }}>
                    {errors.shippingMethod.message}
                  </p>
                )}
                {watch("shippingMethod") === "orderPickup" && (
                  <div className="date-time-picker-container">
                    <Typography variant="h6">Select Pickup Date</Typography>
                    <DatePicker
                      selected={pickupDate}
                      onChange={(date) => setPickupDate(date)}
                      filterDate={isWeekend}
                      placeholderText="Select a Saturday or Sunday"
                      dateFormat="MMMM d, yyyy"
                      className="date-picker"
                    />
                    <Typography variant="h6">Select Pickup Time</Typography>
                    <DatePicker
                      selected={pickupTime}
                      onChange={(time) => setPickupTime(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30} // intervals are set every 60 minutes
                      minTime={new Date().setHours(13, 0, 0)} // 1 PM
                      maxTime={new Date().setHours(19, 0, 0)} // 7 PM
                      dateFormat="h:mm aa"
                      placeholderText="Select a time"
                      className="time-picker"
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="Notes (optional)"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* <Button variant="contained" color="primary" type="submit">
              Place Order Now
            </Button> */}
          </form>
        </Grid>
        {/* summary */}

        <Grid item xs={12} md={6} className="order-summary">
          <Box mb={3}>
            <Typography variant="h5">Order Summary</Typography>
            <Divider variant="middle" className="divider" />
          </Box>
          <Box ml={3}>
            {cartItems.map((item, index) => (
              <Grid container spacing={2} key={index}>
                {/* Thumbnails - Assuming you have a field `thumbnail` in each item */}
                <Grid item xs={2}>
                  <img src={item.productImage} alt={item.name} width="50" />
                </Grid>
                <Grid item xs={4}>
                  {item.name} (x{item.quantity})
                </Grid>
                <Grid item xs={6}>
                  ${(item.price * item.quantity).toFixed(2)}
                </Grid>
              </Grid>
            ))}
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  Tax (13%)
                  <Tooltip
                    style={{ marginLeft: "2px" }}
                    title="Harmonized Sales Tax (HST) of 13%"
                  >
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  ${calculateTax(calculateTotal())}
                </Grid>

                <Grid item xs={6}>
                  Discount
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ color: discountAmount > 0 ? "red" : "inherit" }}
                >
                  -${discountAmount.toFixed(2)}
                </Grid>

                <Grid item xs={6}>
                  Shipping
                </Grid>
                <Grid item xs={6}>
                  ${shippingCharge.toFixed(2)}
                </Grid>

                <Grid item xs={6}>
                  Total
                </Grid>
                <Grid item xs={6}>
                  <strong>${grandTotal}</strong>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="discounts" mt={3} ml={3}>
            <Typography variant="h6">Discounts</Typography>
            <Divider
              className="divider"
              variant="middle"
              style={{ marginBottom: "10px" }}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ReactSelect
                className="discount-select"
                options={discounts.map((discount) => {
                  return {
                    label: discount.label,
                    value: discount.code,
                    data: discount,
                  };
                })}
                onChange={setSelectedDiscount}
                placeholder="Select discount..."
                isClearable
              />
              <Input
                className="custom-code-input"
                placeholder="Have a code?"
                value={customCode}
                onChange={(e) => setCustomCode(e.target.value)}
                endAdornment={
                  <Button
                    onClick={() =>
                      applyDiscount(customCode || selectedDiscount?.value)
                    }
                  >
                    Apply
                  </Button>
                }
              />
            </Box>
          </Box>
          <Box className={"payment-method"} mt={3} ml={3}>
            <Typography variant="h6">Payment Method</Typography>
            <Divider
              className="divider"
              variant="middle"
              style={{ marginBottom: "10px" }}
            />

            <Box display="flex" alignItems="center">
              <Radio
                checked={true} // Currently, only e-Transfer is available
                value="eTransfer"
                name="payment-method"
              />
              <img
                src="./e-transfer.jpg"
                alt="e-transfer"
                width="60"
                style={{ marginRight: "3px" }}
              />
              <Typography variant="body1">
                E-Transfer (Interac Email Money Transfer)
              </Typography>
            </Box>

            {/* Placeholder for future payment methods */}
            {/* 
  <Box display="flex" alignItems="center" mt={1}>
    <Radio
      checked={false}
      value="creditCard"
      name="payment-method"
    />
    <Typography variant="body1">Credit Card (Coming Soon)</Typography>
  </Box>
  */}
          </Box>
          <Box mt={4} ml={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Divider
                className="divider"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
              <Grid
                container
                // justifyContent="center"
                style={{ marginTop: "20px" }}
              >
                <FormControl required error={!!errors.acceptConditions}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("acceptConditions", {
                          required:
                            "You must accept the conditions to place an order",
                        })}
                      />
                    }
                    label={
                      <Typography variant="body1" color="error">
                        I understand this is not meant to be consumed by humans.
                        And for botanical purposes only.
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl required error={!!errors.acceptThatIAm18}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("acceptThatIAm18", {
                          required:
                            "You must confirm that you are 18 years or older to place an order",
                        })}
                      />
                    }
                    label={
                      <Typography variant="body1" color="error">
                        I confirm that I am 18 years old or older.
                      </Typography>
                    }
                  />
                </FormControl>
                {showNoTrackingCheckbox && (
                  <FormControl
                    required
                    error={!!errors.noTrackingAcknowledgement}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register("noTrackingAcknowledgement", {
                            required:
                              "You must acknowledge that tracking is not available for the Mail option, delivery may take 7-14 business days, and Mystics Leaf is not liable for any delays or lost packages.",
                          })}
                        />
                      }
                      label={
                        <Typography variant="body1" color="error">
                          I acknowledge that tracking is not available for the
                          Mail option, delivery may take 7-14 business days, and
                          Mystics Leaf is not liable for any delays or lost
                          packages.
                        </Typography>
                      }
                    />
                  </FormControl>
                )}
              </Grid>
              {errors.acceptConditions && (
                <Typography
                  style={{ margin: "5px" }}
                  variant="body2"
                  color="error"
                >
                  {errors.acceptConditions.message}
                </Typography>
              )}
              {errors.acceptThatIAm18 && (
                <Typography
                  style={{ margin: "5px" }}
                  variant="body2"
                  color="error"
                >
                  {errors.acceptThatIAm18.message}
                </Typography>
              )}
              {errors.noTrackingAcknowledgement && (
                <Typography
                  style={{ margin: "5px" }}
                  variant="body2"
                  color="error"
                >
                  {errors.noTrackingAcknowledgement.message}
                </Typography>
              )}

              <Box mt={2}>
                <Button
                  className="place-order-btn"
                  variant="contained"
                  color="success"
                  size="large"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Submitting Order.." : "Place Order"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={handleModalOpen}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: "bold", fontSize: "1.5em" }}
          id="customized-dialog-title"
        >
          Payment Instructions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            navigate("/home");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 3 }}>
          <Typography gutterBottom sx={{ marginBottom: 2 }}>
            To complete your purchase, please follow the instructions below for
            email interact payments:
          </Typography>
          <Typography gutterBottom sx={{ marginBottom: 2 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              1.
            </Box>{" "}
            Log in to your banking application and select the "Email Money
            Transfer" or "e-Transfer" option.
          </Typography>
          <Typography gutterBottom sx={{ marginBottom: 2 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              2.
            </Box>{" "}
            Use the following details to send the payment:
            <ul>
              <li>
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  Email:
                </Box>{" "}
                support@mysticsleaf.com
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  Question:
                </Box>{" "}
                What year is it for Mystics?
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  Answer:
                </Box>{" "}
                Leaf2023
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: "bold", color: "red" }}>
                  Amount to Send:
                </Box>{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>
                  ${grandTotal}
                </span>
                <Typography variant="body2" color="textSecondary">
                  This is the total amount for your order. Please ensure you
                  send the exact amount.
                </Typography>
              </li>
              <li>
                <Box component="span" sx={{ fontWeight: "bold", color: "red" }}>
                  IMPORTANT - Message (Order ID):
                </Box>{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {orderId}
                </span>
                <Typography variant="body2" color="textSecondary">
                  Ensure you include this order ID in your e-Transfer message to
                  avoid payment delays.
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography gutterBottom>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              3.
            </Box>{" "}
            Once the payment has been sent, please wait for a confirmation email
            from us before considering your order as confirmed.
          </Typography>
          <Typography gutterBottom color="textSecondary" sx={{ marginTop: 2 }}>
            Additionally, we will send you an email with further instructions
            regarding your order.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
              navigate("/home");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* <Footer /> */}
    </div>
  );
}
