import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../shared/Navbar";
import { Grid, Stack, Button, Box, Typography, Chip } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import firestoreService from "../../services/firestoreServices";
import { logCustomEvent } from "../../services/analyticsLogger";
import "./ProductPage.css";

export default function ProductsPage() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    firestoreService.getProducts().then((tempProducts) => {
      setProducts(tempProducts);
    });
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    logCustomEvent("screen_view", {
      firebase_screen: "products_page",
      uid,
    });
  }, []);

  const OutOfStockOverlay = () => (
    <Box
      sx={{
        position: "absolute",
        top: "10%",
        right: "10%",
        bgcolor: "secondary.main",
        px: 2,
        py: 1,
        transform: "rotate(45deg)",
        transformOrigin: "0 0",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Typography variant="subtitle1" color="white">
        Out of Magic
      </Typography>
    </Box>
  );

  // Function to render product items based on their category
  const renderProductItems = (category) => {
    return products
      .filter((product) => product.category === category)
      .map((product) => {
        let priceDisplay;
  
        // check if variants exist and there are multiple variants
        if (product?.variants && product.variants.length > 1) {
          // get all prices for this product's variants
          const prices = product.variants.map((variant) => variant.price);
          // find min and max prices
          const minPrice = Math.min(...prices);
          const maxPrice = Math.max(...prices);
          priceDisplay = `$${minPrice} - $${maxPrice}`;
        } else if (product?.variants && product.variants.length === 1) {
          // only one variant exists
          priceDisplay = `$${product.variants[0].price}`;
        }
  
        return (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            key={product.slug}
            container
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            position="relative"
            sx={{ overflow: "hidden"}}
          >
           <div style={{padding:'10px'}}>
      {!product.inStock && <OutOfStockOverlay />}
      <img
        src={product.productImages[0]}
        alt={product.title}
        style={{
          width: "100%",
          maxWidth: "320px",
          borderRadius: "4px",
        }}
        onClick={() => {
          navigate("/shop/" + product.slug);
        }}
      />
      <Link
        to={`/shop/${product.slug}`}
        style={{ textDecoration: "none", textAlign: "center" }}
      >
        <Typography variant={isMobile ? "body1" : "h5"} style={{ color: "#2a5934", marginTop: '10px' }}>
          {product.mysticTitle}
        </Typography>
        <Typography variant={isMobile ? "body2" : "h6"} style={{ color: "#2a5934",marginTop: '10px' }}>
          {product.title}
        </Typography>
      </Link>
      <Typography variant={isMobile ? "body2" : "h6"} style={{ color: "#2a5934",marginTop: '10px' }}>

        {priceDisplay}
      </Typography>
    </div>
          </Grid>
        );
      });
  };
  
  return (
    <div className="product-container">
      <Navbar />

      <Box
        bgcolor="#5b954b"
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={4}
        boxShadow="0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)"
      >
        <ShoppingBasketIcon sx={{ color: "#e0e0e0", fontSize: "70px" }} />
        <Typography variant="h4" color="#e0e0e0" fontWeight="500" mt={2}>
          Shop
        </Typography>
      </Box>

      <Typography
        variant="h3"
        gutterBottom
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        Powders
      </Typography>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="flex-start" // Align items to the left
        flexWrap="wrap" // Allow items to wrap
        style={{ margin: isMobile ? "" : "20px 0", marginBottom: "50px" }}
      >
        {renderProductItems("powder")}
      </Grid>

      {renderProductItems("extracts").length > 0 && (
        <>
          <Typography
            variant="h3"
            gutterBottom
            style={{ textAlign: "center", marginTop: "40px" }}
          >
            Extracts 
          </Typography>
          
          {/* <Typography
            variant="h4"
            gutterBottom
            style={{ textAlign: "center", marginTop: "40px", color: "#184438" }}
          >
            (Available April 17th 2024)
          </Typography> */}
        </>
      )}
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="flex-start" // Align items to the left
        flexWrap="wrap" // Allow items to wrap
        style={{ margin: isMobile ? "" : "20px 0" }}
      >
        {renderProductItems("extracts")}
      </Grid>
    </div>
  );
}
