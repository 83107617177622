import React, { useState, useEffect } from 'react';
import Blog from './Blog'; // Import the Blog component
import firestoreService from '../../services/firestoreServices';
import Navbar from '../shared/Navbar';
import './Blogs.css';
import { Helmet } from 'react-helmet'; // You might need to install the react-helmet package

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogData = await firestoreService.getBlogs();
        setBlogs(blogData);
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
    };

    fetchBlogs();
  }, []);

  const blogStructuredData = {
    "@context": "http://schema.org",
    "@type": "Blog",
    "name": "Mystics Leaf Blog",
    "url": "https://www.mysticsleaf.com/blog",
    "description": "Explore the Mystics Leaf Blog for insightful articles on Kratom, its uses, legality, and much more.",
    "author": {
      "@type": "Organization",
      "name": "Mystics Leaf"
    },
    "blogPost": [
      {
        "@type": "BlogPosting",
        "headline": "A Toronto Resident's Guide to Kratom Strains: Finding Your Perfect Match",
        "url": "https://www.mysticsleaf.com/blog/a-toronto-residents-guide-to-kratom-strains-finding-your-perfect-match",
        // Additional properties like datePublished, image, author, etc.
      },
      {
        "@type": "BlogPosting",
        "headline": "Kratom Ontario: A Journey Through the Province's Kratom Scene",
        "url": "https://mysticsleaf.com/blog/kratom-ontario-a-journey-through-the-provinces-kratom-scene",
        // Additional properties
      },
      {
        "@type": "BlogPosting",
        "headline": "Buy Kratom in Toronto, Ontario",
        "url": "https://mysticsleaf.com/blog/buy-kratom-in-toronto-ontario",
        // Additional properties
      },
      {
        "@type": "BlogPosting",
        "headline": "Kratom in the GTA: Understanding Its Legal Status and Where to Find It",
        "url": "https://mysticsleaf.com/blog/kratom-in-the-gta-understanding-its-legal-status-and-where-to-find-it",
        // Additional properties
      },
      {
        "@type": "BlogPosting",
        "headline": "Welcome to Mystics Leaf: Your Portal to Natural Wellness",
        "url": "https://mysticsleaf.com/blog/welcome-to-mystics-leaf-your-portal-to-natural-wellness",
        // Additional properties
      }
    ]
  };
  
  return (
    <>
    <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(blogStructuredData)}
        </script>
      </Helmet>
    <Navbar />
    <header className="blog-header">
        <h1>Mystics Leaf Blog</h1>
      </header>
      <div className="blogs-container">
        {blogs.map((blog) => (
          <Blog key={blog.id} {...blog} />
        ))}
      </div>
    </>
  );
};

export default Blogs;
