import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Visibility as VisibilityIcon } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back arrow icon;
import firestoreService from '../../services/firestoreServices';
import Navbar from '../shared/Navbar';
import './BlogPost.css'; // Ensure you import your CSS for styling
import { logCustomEvent } from '../../services/analyticsLogger';
import Button from '@mui/material/Button';

const BlogPost = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate(); // Hook to navigate
  useEffect(() => {
    const fetchBlogPost = async () => {
      setLoading(true);
      try {
        const fetchedBlogPost = await firestoreService.viewBlogBySlug(slug);
        if (fetchedBlogPost) {
          setBlogPost(fetchedBlogPost);
        } else {
          setError('Blog post not found.');
        }
      } catch (error) {
        setError('Error fetching blog post.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
   
  }, [slug]);

  useEffect(() => {
    if(!blogPost) return;

    const uid = sessionStorage.getItem('uid');
    logCustomEvent('screen_view', {
      firebase_screen: 'blog_post',
      uid: uid,
      blogPostId: blogPost?.id,
    });

    if (uid) {
        firestoreService.incrementBlogView(blogPost.id, uid);
      }
    }, [blogPost]);

  if (loading) {
    return <div className="blog-post-loading">Loading...</div>;
  }

  if (error) {
    return <div className="blog-post-error">{error}</div>;
  }

  const goBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

    return (
    <>
      <Navbar />
      <div className="blog-post-container">
        <header className="blog-post-header">
          <Button onClick={goBack} className="blog-post-back-button">
            <ArrowBackIcon /> Back
          </Button>
          <h1>{blogPost?.title}</h1>
          <div className="blog-post-meta">
            <span className="blog-post-author">{blogPost?.author}</span>
            <span className="blog-post-date">{blogPost?.publishedDate}</span>
            <span className="blog-post-views">
              <VisibilityIcon /> {blogPost?.views?.length || 0}
            </span>
          </div>
        </header>
        <article className="blog-post-content">
          {blogPost && (
            <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
          )}
        </article>
      </div>
    </>
  );
};

export default BlogPost;
