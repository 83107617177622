import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, TextField, Button, IconButton, Typography } from '@mui/material';
import { Outlet, Link, useNavigate, useMatch, useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const PopupBanner = ({ title, message, actionText, onActionClick }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const isDismissed = localStorage.getItem('popupBannerDismissed') === 'true';
        if (!isDismissed) {
            setOpen(true);
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('popupBannerDismissed', 'true');
    };

    //   const handleSubmit = () => {
    //     setOpen(false);
    //     localStorage.setItem('popupBannerDismissed', 'true');
    //   };

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: '#64a147', color: '#fff' } }}>
            <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}>
                <CloseIcon />
            </IconButton>
            <DialogContent style={{ textAlign: 'center', padding: '40px' }}>
                <Typography variant="h4" component="div" gutterBottom style={{ color: 'white', fontWeight: 'bold', marginBottom: '20px' }}>
                    Get %10 Off Your First Order
                </Typography>
                <Typography variant="body1" component="div" gutterBottom style={{ color: 'white', marginBottom: '20px' }}>
                    Join now to receive a 10% discount on your first order! Plus, earn points towards free Kratom or store credits with every purchase. Stay informed about exclusive deals and events — only for our community members.
                </Typography>

                <Button variant="contained" type="submit" fullWidth style={{ backgroundColor: 'green', color: 'white' }} onClick={() => {
                    setOpen(false);
                    localStorage.setItem('popupBannerDismissed', 'true');
                    navigate('/account')

                }}>
                    Continue
                </Button>
                <Button onClick={handleClose} style={{ color: 'white', textTransform: 'none', marginTop: '20px' }}>
                    No discount, thanks
                </Button>

            </DialogContent>
        </Dialog>
    );
};

export default PopupBanner;
