import React from "react";
import { Grid, Typography, Link, Box } from "@mui/material";
import {
  Instagram,
  Email,
  VerifiedUser,
  AccountBalanceWallet,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer
      style={{
        backgroundColor: "rgb(91, 149, 75)",
        padding: "3rem 2rem",
        textAlign: "center",
        bottom: 0,
        marginTop: "auto",

        width: "100%",
      }}
    >
      <Grid container spacing={4} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={3}>
        <img src="/KratomLeaf.webp" alt="Mystic's Leaf" style={{ width: "79px", height: '100%' }} />
        <Typography variant="h4" color="#d3ffa8" gutterBottom>
          Mystic's Leaf
        </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          {/* <Typography variant="h6" gutterBottom>
            &copy; {new Date().getFullYear()} Mystic's Leaf
          </Typography> */}
          <Box mt={2}>
          <Typography variant="h6" gutterBottom>
          CATEGORIES
          </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/shop/super-green-meng-da")
            }}>
               Buy Super Green Kratom in Toronto
             
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/shop/super-red-maeng-da")
            }}>
               Buy Super Red Kratom in Toronto
             
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/shop/white-maeng-da")
            }}>
               Buy White Kratom in Toronto
             
            </Typography>
          </Box>
          {/* <Box mt={2}>
            <Link
              href="https://www.instagram.com/mystics_leaf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram style={{ fontSize: 30, color: "#3f729b" }} />
            </Link>
          </Box>
          <Box mt={2}> 
          <Typography>
            <Link
              href="mailto:support@mysticsleaf.com"
              color="primary"
              style={{ textDecoration: "none" }}
              underline="hover"
            >
              <Email style={{ verticalAlign: "bottom" }} />{" "}
              support@mysticsleaf.com
            </Link>
          </Typography>
          </Box> */}
        </Grid>

        

        <Grid item xs={12} sm={3}>
          <Typography variant="h6" gutterBottom>
          NAVIGATION
          </Typography>
          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <AccountBalanceWallet style={{ fontSize: 30, color: "#1565c0" }} />
            <Typography variant="body1" style={{ marginLeft: "0.5rem" }}>
              Safe & Secure
            </Typography>
          </Box> */}
           <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem",cursor:'pointer' }} color={"white"} onClick={()=>{
              navigate("/account")
            }}>
               My Account
             
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem",cursor:'pointer' }} color={"white"} onClick={()=>{
              navigate("/faq")
            }}>
               F.A.Q
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/terms-of-purchase")
            }}>
              Terms of Purchase
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/blogs")
            }}>
              Blog
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/privacy-policy")
            }}>
              Privacy Policy
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Typography variant="h6" style={{ marginLeft: "0.5rem" ,cursor:'pointer'}} color={"white"} onClick={()=>{
              navigate("/return-exchange-policy")
            }}>
              Return & Exchange Policy
            </Typography>
          </Box>
        </Grid>
        
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography>
            <Link
              href="mailto:support@mysticsleaf.com"
              color="primary"
              style={{ textDecoration: "none" }}
              underline="hover"
            >
              <Email style={{ verticalAlign: "bottom" }} />{" "}
              support@mysticsleaf.com
            </Link>
          </Typography>
          <Box mt={2}>
            <Link
              href="https://www.instagram.com/mystics_leaf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram style={{ fontSize: 30, color: "#3f729b" }} />
            </Link>
          </Box>
        </Grid>

        {/* <Grid item xs={12} sm={3}>
          <Typography variant="h6" gutterBottom>
            Secure Checkout
          </Typography>
          <Link
            href="https://www.sslcertificates.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <VerifiedUser style={{ fontSize: 50, color: "#4caf50" }} />
          </Link>
        </Grid> */}
      </Grid>
      <Box mt={2}>
      <Typography  gutterBottom>
            &copy; Copyright {new Date().getFullYear()} Mystic's Leaf. All Rights Reserved.
          </Typography>
      </Box>
    </footer>
  );
}
