import React from 'react';

import Navbar from '../shared/Navbar';

const PrivacyPolicy = () => {
  return (
    <div>
        <Navbar />
      <h1>Privacy Policy for Mystic Leaf</h1>

      <p>At Mystic Leaf, accessible from mysticsleaf.ca, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Mystic Leaf and how we use it.</p>

      <h2>Information Collection and Use</h2>
      <p>For a better experience while using our service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.</p>

      <h2>Log Data</h2>
      <p>We want to inform you that whenever you visit our site, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser version, pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

      <h2>Cookies</h2>
      <p>Cookies are files with a small amount of data that is commonly used as an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>
      <p>Our website uses these "cookies" to collect information and to improve our service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our service.</p>

      <h2>Service Providers</h2>
      <p>We may employ third-party companies and individuals due to the following reasons:</p>
      <ul>
        <li>To facilitate our service;</li>
        <li>To provide the service on our behalf;</li>
        <li>To perform service-related services; or</li>
        <li>To assist us in analyzing how our service is used.</li>
      </ul>
      <p>We want to inform our service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

      <h2>Security</h2>
      <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

      <h2>Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@mysticsleaf.ca.</p>
    </div>
  );
}

export default PrivacyPolicy;
