import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";



// Firebase Config
const firebaseConfig = {

  apiKey: "AIzaSyDWajKOQry2TlZyN4l627aHTyxlAD6ks_4",

  authDomain: "mystics-leaf.firebaseapp.com",

  projectId: "mystics-leaf",

  storageBucket: "mystics-leaf.appspot.com",

  messagingSenderId: "356455135703",

  appId: "1:356455135703:web:8eb75b3a83fc5c63713392",

  measurementId: "G-QPBD2PJQ4M"

};


  


// export default { firebaseConfig }
const firebase = initializeApp(firebaseConfig);
// export default firebase;

const analytics = getAnalytics(firebase);
const firestore = getFirestore(firebase);
const auth = getAuth(firebase);
const storage = getStorage(firebase);
const functions= getFunctions(firebase);

export {
    firestore,
    auth,
    storage,
    firebase,
    firebaseConfig,
    analytics,
    functions
}
