import {
  Outlet,
  Link,
  useNavigate,
  useMatch,
  useLocation,
  useParams,
} from "react-router-dom";
import Navbar from "../shared/Navbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Grid,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Avatar,
  Paper,
  Dialog,
  DialogContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import { useTheme, useMediaQuery } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Icon from "@mui/material/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSeedling,
  faPalette,
  faTruckFast,
  faGavel,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import firestoreService from "../../services/firestoreServices";
import { storage } from "../../services/services";
import { toast } from "react-toastify";
import moment from "moment/moment";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LoadingScreen from "../shared/LoadingScreen";
import { logCustomEvent } from "../../services/analyticsLogger";
import { Helmet } from "react-helmet"; // You might need to install the react-helmet package.
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const StarRating = ({ initialRating = 0, onRatingChange, locked = false }) => {
  const [rating, setRating] = useState(initialRating);

  return (
    <div>
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          onClick={() => {
            if (!locked) {
              const newRating = index + 1;
              setRating(newRating);
              onRatingChange && onRatingChange(newRating);
            }
          }}
        >
          {rating > index ? <StarIcon color="primary" /> : <StarBorderIcon />}
        </span>
      ))}
    </div>
  );
};

const StyledImage = styled("img")({
  width: "100%",
  // transition: "transform 0.5s ease",
  // "&:hover": {
  //   transform: "scale(1.2)",
  // },
});

// export const loader = async ({ params }) => {
//   const product = await getProduct(params.slug);
//   return json(product);
// };

const StyledCommentBox = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  mt: 3,
  border: "1px solid #eee",
  borderRadius: 5,
  p: 2,
  backgroundColor: "#f9f9f9",
  position: "relative",
});

const CommentAvatar = styled(Avatar)({
  marginRight: "16px",
  backgroundColor: "#ddd",
});

const CommentContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const StyledTextBox = styled(TextField)({
  width: "100%",
});

const StyledPostButton = styled(Button)({
  width: "100%",
  mt: 2,
});

function Comment({ comment, onEdit }) {
  console.log("comment", comment);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  return (
    <StyledCommentBox>
      <Grid
        container
        spacing={isMobile ? 1 : 1}
        style={{ padding: isMobile ? "5px" : "10px" }}
      >
        <Grid item xs={12} sm={2}>
          <CommentAvatar>
            {comment.username ? comment.username[0] : "G"}
          </CommentAvatar>
          <Typography variant="h6" color="primary">
            {comment.username ? comment.username : "Guest"}
          </Typography>
          <StarRating locked={true} initialRating={comment.rating} />
        </Grid>

        <Grid item xs={12} sm={10}>
          <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
            {comment.content}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginTop: "8px" }}
          >
            {moment.unix(comment.timestamp).format("MMMM Do YYYY")}
          </Typography>

          {comment.images &&
            comment.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Preview ${index}`}
                style={{ width: 100, height: 100, cursor: "pointer" }}
                onClick={() => handleOpen(image)}
              />
            ))}

          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <img
                src={selectedImage}
                alt="Enlarged preview"
                style={{ width: "100%" }}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </StyledCommentBox>
  );
}

export default function ProductPage() {
  // const productImages = [
  //   "https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/productImages%2Ffront_shot_package.jpg?alt=media&token=2583efea-0c54-43ce-9160-9608bedb8b74",
  //   "https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/productImages%2Fsuper-green-meng-da-toronto.webp?alt=media&token=339547b1-fb40-4e60-8c60-1c18eab1f17a",
  //   "https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/productImages%2Fsuper-green-meng-da-toronto-2.webp?alt=media&token=65f15090-2f4c-45d0-8df5-f747a36727b3",
  // ];
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [comments, setComments] = useState([]); // Example: [{username: 'John', content: 'Great product!', timestamp: '2023-04-05T12:00:00Z'}, ...]
  const [userComment, setUserComment] = useState("");
  const [userHeader, setUserHeader] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [mainImage, setMainImage] = useState(
    "/super-green-meng-da-toronto.webp"
  ); // default main image
  const [selectedVariant, setSelectedVariant] = useState({});
  const [rating, setRating] = useState(0); // 0-5
  const [userUsername, setUserUsername] = useState("");

  const { productId } = useParams();
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [weightSelected, setWeightSelected] = useState(0);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [ratingError, setRatingError] = useState(false); // State to handle error for rating

  // Handles setting the previous image in the carousel
  const handlePreviousImage = () => {
    setMainImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : productImages.length - 1
    );
  };
  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    logCustomEvent("screen_view", {
      firebase_screen: "product_page",
      uid: uid,
      product_id: productId,
    });
  }, []);
  // Handles setting the next image in the carousel
  const handleNextImage = () => {
    setMainImageIndex((prevIndex) =>
      prevIndex < productImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleEditComment = (commentToEdit) => {
    // Implement logic to edit a comment
    // This can include popping up a modal with the comment's text and allowing for editing
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);

    const fileReaders = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    });

    const previews = [];
    fileReaders.forEach((reader) => {
      reader.onload = () => {
        previews.push(reader.result);
        setImagePreviews([...previews]);
      };
    });
  };

  useEffect(() => {
    firestoreService.getProduct(productId).then((data) => {
      setSelectedVariant(data?.variants[0].sku);
      setWeightSelected(data?.variants[0].quantity);
      setProductImages(data?.productImages);
      setProduct(data);
    });
  }, [productId]);

  useEffect(() => {
    console.log("show me varient", selectedVariant);
    const tempVariant = product?.variants.find(
      (variant) => variant.sku === selectedVariant
    );
    setWeightSelected(tempVariant?.quantity);
  }, [selectedVariant]);

  useEffect(() => {
    // Fetch comments for this product from your backend
    if (product?.id) {
      console.log("product id", product?.id);
      firestoreService.getCommentsForProduct(product?.id).then((data) => {
        console.log("data", data.docs);
        const tempComments = data.docs.map((doc) => {
          return doc.data().comment;
        });
        if (Array.isArray(data.docs)) {
          setComments(tempComments);
        } else {
          console.error("Returned data is not an array:", data);
        }
      });
    }
  }, [product?.id]);

  const handleAddComment = () => {
    let username = userUsername.trim() || sessionStorage.getItem("username");
    const uid = sessionStorage.getItem("uid");

    if (!rating) {
      setRatingError(true); // Set error state true if no rating
      return; // Prevent further execution
    }

    // Upload each image and collect their URLs
    const uploadPromises = images.map((image) => {
      const imageRef = ref(storage, `images/reviews/${uid}/${image.name}`);
      return uploadBytes(imageRef, image).then(() => getDownloadURL(imageRef));
    });

    Promise.all(uploadPromises).then((imageUrls) => {
      const newComment = {
        username,
        email: userEmail,
        header: userHeader,
        content: userComment,
        images: imageUrls, // Save URLs to Firestore
        rating,
        commentCanBeDisplayed: true,
        timestamp: moment.utc().unix(),
      };

      firestoreService
        .addCommentForProduct(uid, product?.id, newComment)
        .then(() => {
          toast.success("Your review has been posted successfully!");
          setComments([newComment, ...comments]);
          setUserComment("");
          setUserEmail("");
          setUserHeader("");
          setUserUsername("");
          setImages([]);
          setImagePreviews([]);
          setRating(0);
        })
        .catch((error) => {
          console.error("Error adding comment:", error);
          toast.error(
            "An error occurred while posting your review. Please try again later."
          );
        });
    });
  };

  const [quantity, setQuantity] = useState(1);

  const addToCart = (userSelectedVariant, quantity) => {
    // call your add to cart function here

    const tempVariant = product?.variants.find(
      (variant) => variant.sku === userSelectedVariant
    );

    firestoreService
      .addToCart(product, tempVariant, quantity, sessionStorage.getItem("uid"))
      .then(() => {
        toast.success("Item Added To Cart!");
        navigate("/cart");
      });
  };

  let minPrice;
  let maxPrice;
  let selectedPrice;
  let priceDisplay;

  if (product?.variants?.length) {
    const prices = product.variants.map((variant) => variant.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    selectedPrice = product.variants.find(
      (variant) => variant.sku === selectedVariant
    )?.price;

    // Determine how to display the price based on the number of variants
    if (prices.length === 1) {
      priceDisplay = `$${minPrice}`; // Single price display
    } else {
      priceDisplay = `$${minPrice} - $${maxPrice}`; // Range display
    }
  } else {
    priceDisplay = "Price not available"; // No variants available
  }
  // if (product?.variants) {
  //   const prices = product?.variants.map((variant) => variant.price);
  //   minPrice = Math.min(...prices);
  //   maxPrice = Math.max(...prices);
  //   selectedPrice = product?.variants.find(
  //     (variant) => variant.sku === selectedVariant
  //   )?.price;
  // }

  // if (product?.variants && product.variants.length > 1) {
  //   // get all prices for this product's variants
  //   const prices = product.variants.map((variant) => variant.price);
  //   // find min and max prices
  //   const minPrice = Math.min(...prices);
  //   const maxPrice = Math.max(...prices);
  //   selectedPrice = product?.variants.find(
  //     (variant) => variant.sku === selectedVariant
  //   )?.price;
  //   priceDisplay = `$${minPrice} - $${maxPrice}`;
  // } else if (product?.variants && product.variants.length === 1) {
  //   // only one variant exists
  //   priceDisplay = `$${product.variants[0].price}`;
  // }

  const imgRef = useRef(null);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    imgRef.current.style.transformOrigin = `${x}% ${y}%`;
  };

  const total = selectedPrice * quantity;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const productPageStructuredData = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Super Green Kratom",
    description:
      "A premium quality Kratom strain offering a unique blend of benefits for wellness and vitality.",
    url: "https://mysticsleaf.com/shop/super-green-meng-da",
    image: "https://mysticsleaf.com/super-green-meng-da-toronto.jpg",
    offers: {
      "@type": "Offer",
      priceCurrency: "CAD",
      price: "10.00",
      availability: "http://schema.org/InStock",
      url: "https://mysticsleaf.com/shop/super-green-meng-da",
    },
    // You can add more product-specific details like SKU, brand, etc. here.
  };

  return (
    <>
      {product && (
        <div>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(productPageStructuredData)}
            </script>
          </Helmet>
          <Navbar />
          <Grid container spacing={2} padding={"20px 0"} marginTop={"30px"}>
            <Grid item md={6} xs={12}>
              <StyledImage
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "cover",
                  padding: "10px",
                }}
                onMouseMove={handleMouseMove}
                src={productImages[mainImageIndex]}
                alt={product?.title}
                ref={imgRef}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                {/* Left navigation button */}
                <IconButton
                  onClick={handlePreviousImage}
                  aria-label="previous image"
                  sx={{
                    mr: 1,
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>

                {/* Thumbnails can also be made into a horizontal scrollable area for overflow */}
                <Box
                  sx={{
                    overflowX: "auto",
                    display: "flex",
                  }}
                >
                  {productImages.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`thumbnail-${index}`}
                      style={{
                        width: "50px",
                        marginRight: "5px",
                        cursor: "pointer",
                        border:
                          mainImageIndex === image
                            ? "2px solid #2a5934"
                            : "none",
                      }}
                      onClick={() => {
                        setMainImageIndex(index);
                        setMainImage(image);
                      }}
                    />
                  ))}
                </Box>

                {/* Right navigation button */}
                <IconButton
                  onClick={handleNextImage}
                  aria-label="next image"
                  sx={{
                    ml: 1,
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "#2e7d32",
                    textAlign: "center",
                    padding: "10px",
                  }}
                  variant={isMobile ? "h4" : "h3"}
                  gutterBottom
                >
                  {product?.mysticTitle}
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "#2e7d32",
                    textAlign: "center",
                  }}
                  variant={isMobile ? "h5" : "h4"}
                  gutterBottom
                >
                  {product?.title}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ color: "grey" }}
                  sx={{ mt: 1 }}
                >
                  {priceDisplay}
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {product?.description}
                </Typography>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="variant-label">Choose Size</InputLabel>
                  <Select
                    labelId="variant-label"
                    id="variant-select"
                    label="Choose Size"
                    value={selectedVariant}
                    onChange={(e) => setSelectedVariant(e.target.value)}
                  >
                    {product?.variants.map((variant) => (
                      <MenuItem value={variant.sku} key={variant.sku}>
                        {variant.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Quantity"
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  InputProps={{
                    inputProps: { min: 1 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setQuantity(Math.max(1, quantity - 1))}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setQuantity(quantity + 1)}>
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mt: 2 }}
                />
                <Typography variant="h6" color="text.secondary" sx={{ mt: 2 }}>
                  Total: ${total.toFixed(2)}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#81c784", marginTop: "20px" }}
                  sx={{ mt: 2 }}
                  onClick={() => {
                    if (product.inStock === true) {
                      addToCart(selectedVariant, quantity);
                    } else {
                      toast.error("This product is out of stock", {
                        theme: "colored",
                      });
                    }
                  }}
                >
                  {product.inStock === true ? "Add to Cart" : "Out of Stock"}
                  {/* Add to Cart */}
                </Button>
                <Box sx={{ mt: 2 }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <DescriptionIcon
                          sx={{ color: "#2e7d32", marginRight: "8px" }}
                        />{" "}
                        Description
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: "#f1f8e9", padding: "16px" }}
                    >
                      {product.color === "extract80%" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for enhanced readability
                              fontSize: "1.25rem", // Appropriately large font size for easy reading
                              lineHeight: "1.6", // Optimal line height for better readability
                              textAlign: "left", // Text aligned to the left for a smoother reading experience
                              fontWeight: "normal", // Standard weight to maintain readability without being too bold
                              background: "rgba(255, 255, 255, 0.7)", // A semi-transparent white background for text emphasis
                              padding: "10px", // Padding to ensure text is comfortably distanced from the edges
                              borderRadius: "4px", // Softly rounded corners for a visually appealing presentation
                            }}
                          >
                            Mystic Essence - Platinum Kratom Extract
                          </Typography>
                          <Typography sx={{ color: "#424242" }}>
                            Imbued with the purest essence of magic, the Mystic
                            Essence - Platinum Kratom Extract stands as a
                            testament to Mystics Leaf's alchemical prowess.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>
                              Created through advanced extraction techniques,
                              this elixir boasts an almost ethereal white hue,
                              signifying its unparalleled purity and potency.
                            </li>
                            <li>
                              Each gram of our extract embodies the essence of
                              80 grams of the most potent Kratom leaves,
                              harvested from mystical groves.
                            </li>
                            <li>
                              Dominated by a majestic 65% Mitragynine content,
                              complemented by a rich tapestry of other alkaloids
                              including 7-Hydroxymitragynine, Corynoxine A & B,
                              and more.
                            </li>
                            <li>
                              Dive deep into the arcane with every granule of
                              this extraordinary extract—a gateway to
                              experiencing the formidable powers bestowed by
                              nature's most secretive spirits.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Keeping the text color consistent for harmony
                              lineHeight: "1.6", // Matching line height for a uniform appearance
                              background: "rgba(255, 255, 255, 0.7)", // Maintaining the semi-transparent overlay for readability
                              padding: "10px", // Consistent padding for a neat and tidy layout
                              borderRadius: "4px", // Keeping the corners rounded for a cohesive look
                            }}
                          >
                            You will not discover a more potent or purer form of
                            Kratom anywhere in the realms of men or mystics.
                          </Typography>
                        </>
                      )}

                      {product.color === "gummy" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for enhanced readability
                              fontSize: "1.25rem", // Appropriately large font size for easy reading
                              lineHeight: "1.6", // Optimal line height for better readability
                              textAlign: "left", // Text aligned to the left for a smoother reading experience
                              fontWeight: "normal", // Standard weight to maintain readability without being too bold
                              background: "rgba(255, 255, 255, 0.7)", // A semi-transparent white background for text emphasis
                              padding: "10px", // Padding to ensure text is comfortably distanced from the edges
                              borderRadius: "4px", // Softly rounded corners for a visually appealing presentation
                            }}
                          >
                            Sorcerer's Sweets - Gummy Extracts: A Mystical
                            Confectionery Adventure
                          </Typography>
                          <Typography sx={{ color: "#388e3c" }}>
                            Embark upon a fantastical journey with Sorcerer's
                            Sweets - Gummy Extracts, the crown jewel of Mystics
                            Leaf's magical collection.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>
                              Perfectly crafted flavor and texture for an
                              enchanting taste experience.
                            </li>
                            <li>
                              Each gummy is imbued with 10mg of pure
                              Mitragynine, ensuring a potent magical essence.
                            </li>
                            <li>
                              A delightful blend of mysticism and nature's
                              alchemy presented in a sweet form.
                            </li>
                            <li>
                              Designed for aficionados of the arcane seeking a
                              touch of enchantment in their quest.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Keeping the text color consistent for harmony
                              lineHeight: "1.6", // Matching line height for a uniform appearance
                              background: "rgba(255, 255, 255, 0.7)", // Maintaining the semi-transparent overlay for readability
                              padding: "10px", // Consistent padding for a neat and tidy layout
                              borderRadius: "4px", // Keeping the corners rounded for a cohesive look
                            }}
                          >
                            Let Sorcerer's Sweets - Gummy Extracts guide you
                            through a realm where flavor and magic intertwine.
                          </Typography>
                        </>
                      )}

                      {product.color === "green" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for better contrast
                              fontSize: "1.25rem", // Larger font size for readability
                              lineHeight: "1.6", // Increased line height for readability
                              textAlign: "left", // Left align text for easier reading
                              fontWeight: "normal", // Use normal weight to avoid overly bold text
                              background: "rgba(255, 255, 255, 0.7)", // Semi-transparent white overlay
                              padding: "10px", // Padding to ensure text doesn't touch the edges
                              borderRadius: "4px", // Rounded corners for aesthetic preference
                            }}
                          >
                            Celestial Clover Enhanced - Super Green Maeng Da!
                          </Typography>
                          <Typography sx={{ color: "#388e3c" }}>
                            Delve into the mystical aura of the Celestial Clover
                            Enhanced - Super Green Maeng Da. Sourced from the
                            deep forests, this blend is a true gem of Mystics
                            Leaf.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>Known for its unmatched vigor and energy.</li>
                            <li>
                              Powerful burst of energy lasting up to three
                              hours.
                            </li>
                            <li>
                              Deep sense of calm and confidence emerges,
                              balancing the vitality.
                            </li>
                            <li>
                              Both invigorating and soothing for a unique
                              experience.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Same dark color for consistency
                              lineHeight: "1.6", // Same line height for consistency
                              background: "rgba(255, 255, 255, 0.7)", // Same semi-transparent overlay
                              padding: "10px", // Same padding
                              borderRadius: "4px", // Same rounded corners
                            }}
                          >
                            Experience the energy, calm, and confidence the
                            Celestial Clover has to offer.
                          </Typography>
                        </>
                      )}
                      {product.color === "red" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for better contrast
                              fontSize: "1.25rem", // Larger font size for readability
                              lineHeight: "1.6", // Increased line height for readability
                              textAlign: "left", // Left align text for easier reading
                              fontWeight: "normal", // Use normal weight to avoid overly bold text
                              background: "rgba(255, 255, 255, 0.7)", // Semi-transparent white overlay
                              padding: "10px", // Padding to ensure text doesn't touch the edges
                              borderRadius: "4px", // Rounded corners for aesthetic preference
                            }}
                          >
                            Celestial Clover Enhanced - Super Red Maeng Da!
                          </Typography>
                          <Typography sx={{ color: "#d32f2f" }}>
                            Embark on a journey to the heart of mysticism with
                            the Celestial Clover Enhanced - Super Red Maeng Da.
                            Harvested from the lush wilderness, this variety
                            stands as the cornerstone of the Mystics Leaf
                            collection.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>
                              Renowned for its profound tranquility and soothing
                              effects.
                            </li>
                            <li>
                              A harmonious blend that offers relaxation and a
                              sense of well-being.
                            </li>
                            <li>
                              Encourages a peaceful state, allowing for deep
                              reflection and serenity.
                            </li>
                            <li>
                              An ideal companion for evening relaxation or
                              meditative practices.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Same dark color for consistency
                              lineHeight: "1.6", // Same line height for consistency
                              background: "rgba(255, 255, 255, 0.7)", // Same semi-transparent overlay
                              padding: "10px", // Same padding
                              borderRadius: "4px", // Same rounded corners
                            }}
                          >
                            Discover the serenity and reflective peace that the
                            Celestial Clover bestows.
                          </Typography>
                        </>
                      )}
                      {product.color === "white" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for better contrast
                              fontSize: "1.25rem", // Larger font size for readability
                              lineHeight: "1.6", // Increased line height for readability
                              textAlign: "left", // Left align text for easier reading
                              fontWeight: "normal", // Use normal weight to avoid overly bold text
                              background: "rgba(255, 255, 255, 0.7)", // Semi-transparent white overlay
                              padding: "10px", // Padding to ensure text doesn't touch the edges
                              borderRadius: "4px", // Rounded corners for aesthetic preference
                            }}
                          >
                            Celestial Clover Enhanced - Super White Maeng Da!
                          </Typography>
                          <Typography sx={{ color: "#388e3c" }}>
                            Immerse yourself in the pure essence of the
                            Celestial Clover Enhanced - Super White Maeng Da.
                            Harvested from the serene wilderness, this blend is
                            the pinnacle of Mystics Leaf’s offerings.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>
                              Renowned for its pristine energy and mental
                              clarity.
                            </li>
                            <li>
                              A refreshing wave of focus that can last for
                              hours.
                            </li>
                            <li>
                              A harmonious balance between stimulation and
                              serenity is achieved, providing a unique
                              sensation.
                            </li>
                            <li>
                              Both enlightening and tranquil for an unparalleled
                              experience.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Same dark color for consistency
                              lineHeight: "1.6", // Same line height for consistency
                              background: "rgba(255, 255, 255, 0.7)", // Same semi-transparent overlay
                              padding: "10px", // Same padding
                              borderRadius: "4px", // Same rounded corners
                            }}
                          >
                            Discover the clarity, focus, and serenity that the
                            Celestial Clover brings to your journey.
                          </Typography>
                        </>
                      )}
                      {product.color === "platinumTablets" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for enhanced readability
                              fontSize: "1.25rem", // Appropriately large font size for easy reading
                              lineHeight: "1.6", // Optimal line height for better readability
                              textAlign: "left", // Text aligned to the left for a smoother reading experience
                              fontWeight: "normal", // Standard weight to maintain readability without being too bold
                              background: "rgba(255, 255, 255, 0.7)", // A semi-transparent white background for text emphasis
                              padding: "10px", // Padding to ensure text is comfortably distanced from the edges
                              borderRadius: "4px", // Softly rounded corners for a visually appealing presentation
                            }}
                          >
                            Alchemist's Triumph - Platinum Kratom Extract
                            Tablets
                          </Typography>
                          <Typography sx={{ color: "#424242" }}>
                            Journey into the heart of ancient alchemy with
                            Alchemist's Triumph - Platinum Kratom Extract
                            Tablets. Crafted through a pioneering extraction
                            process, these tablets are a testament to the
                            highest echelons of quality and potency.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>
                              Utilizing only the finest Kratom leaves, each
                              tablet is precision-extracted to contain an
                              astonishing 65% Mitragynine and 80% total
                              alkaloids.
                            </li>
                            <li>
                              A single 50mg tablet channels the essence of 4
                              grams of premium Kratom leaf, delivering a
                              transcendent experience unparalleled in its
                              intensity.
                            </li>
                            <li>
                              Ideal for both the seasoned Kratom aficionado and
                              the curious newcomer, these tablets redefine
                              expectations, offering a glimpse into the
                              extraordinary.
                            </li>
                            <li>
                              Do not settle for the mundane. Embrace the
                              pinnacle of Kratom potency with our Platinum
                              Kratom Extract Tablets—the most potent and pure
                              form of Kratom ever encapsulated.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Keeping the text color consistent for harmony
                              lineHeight: "1.6", // Matching line height for a uniform appearance
                              background: "rgba(255, 255, 255, 0.7)", // Maintaining the semi-transparent overlay for readability
                              padding: "10px", // Consistent padding for a neat and tidy layout
                              borderRadius: "4px", // Keeping the corners rounded for a cohesive look
                            }}
                          >
                            Ingredients: Microcrystalline Cellulose, Magnesium
                            Stearate, Silica Di Oxide, Di-calcium Phosphate,
                            Standardized Mitragyna Speciosa Extract.
                          </Typography>
                        </>
                      )}

                      {product.color === "ultraEnhancedGreen" && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#424242", // Darker text for enhanced readability
                              fontSize: "1.25rem", // Appropriately large font size for easy reading
                              lineHeight: "1.6", // Optimal line height for better readability
                              textAlign: "left", // Text aligned to the left for a smoother reading experience
                              fontWeight: "normal", // Standard weight to maintain readability without being too bold
                              background: "rgba(255, 255, 255, 0.7)", // A semi-transparent white background for text emphasis
                              padding: "10px", // Padding to ensure text is comfortably distanced from the edges
                              borderRadius: "4px", // Softly rounded corners for a visually appealing presentation
                            }}
                          >
                            Green Oracle's Essence - Ultra Enhanced Super Green
                            Maeng Da
                          </Typography>
                          <Typography sx={{ color: "#424242" }}>
                            Venture into the verdant depths of the most potent
                            mystical energies with Green Oracle's Essence. This
                            Ultra Enhanced Super Green Maeng Da is not merely an
                            extract but a conduit to ancient, concentrated
                            power.
                          </Typography>
                          <ul style={{ textAlign: "left", lineHeight: "1.6" }}>
                            <li>
                              Our exclusive formula includes a concentrated form
                              of alkaloids, with a robust 5% total Mitragynine
                              content, extracted from the finest Kratom leaves.
                            </li>
                            <li>
                              Each batch is infused with highly potent Kratom
                              Extract powder, elevating its intensity to
                              unprecedented levels.
                            </li>
                            <li>
                              Designed for the discerning, experienced Kratom
                              user, this product demands respect for its
                              potency. Newcomers are advised to approach with
                              caution, beginning with modest quantities.
                            </li>
                            <li>
                              We uphold the highest standards of purity and
                              potency. From rigorous testing in our
                              state-of-the-art laboratories to meticulous
                              shipping and handling, every step ensures an
                              unadulterated and powerful experience.
                            </li>
                          </ul>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#424242", // Keeping the text color consistent for harmony
                              lineHeight: "1.6", // Matching line height for a uniform appearance
                              background: "rgba(255, 255, 255, 0.7)", // Maintaining the semi-transparent overlay for readability
                              padding: "10px", // Consistent padding for a neat and tidy layout
                              borderRadius: "4px", // Keeping the corners rounded for a cohesive look
                            }}
                          >
                            Ingredients: Kratom Extract Powder. Trust in the
                            Green Oracle’s Essence for a truly organic, potent,
                            and pristine Kratom experience.
                          </Typography>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>
                        <FontAwesomeIcon icon={faSeedling} color={"#2e7d32"} />{" "}
                        {product.fullTitle}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: "#f1f8e9", padding: "16px" }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: "#424242", // Same dark color for consistency
                          lineHeight: "1.6", // Same line height for consistency
                          background: "rgba(255, 255, 255, 0.7)", // Same semi-transparent overlay
                          padding: "10px", // Same padding
                          borderRadius: "4px", // Same rounded corners
                        }}
                      >
                        {product.color === "ultraEnhancedGreen" && (
                          <>
                            <Typography
                              variant="h6"
                              style={{
                                color: "#424242", // Darker text for enhanced readability
                                fontSize: "1.25rem", // Appropriately large font size for easy reading
                                lineHeight: "1.6", // Optimal line height for better readability
                                textAlign: "left", // Text aligned to the left for a smoother reading experience
                                fontWeight: "normal", // Standard weight to maintain readability without being too bold
                                background: "rgba(255, 255, 255, 0.7)", // A semi-transparent white background for text emphasis
                                padding: "10px", // Padding to ensure text is comfortably distanced from the edges
                                borderRadius: "4px", // Softly rounded corners for a visually appealing presentation
                              }}
                            >
                              Green Oracle’s Essence - Ultra Enhanced Super
                              Green Maeng Da
                            </Typography>
                            <Typography sx={{ color: "#424242" }}>
                              Deep within the emerald heart of an ancient
                              forest, where the air whispers with old magic and
                              the leaves tell tales of old, the Green Oracle
                              bestows her most potent secrets. This sacred
                              offering, known among the mystics as the Ultra
                              Enhanced Super Green Maeng Da, is not merely a
                              product but a profound blessing from the forest
                              herself.
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#424242",
                                lineHeight: "1.6",
                                background: "rgba(255, 255, 255, 0.7)",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                            >
                              Each grain of this enhanced Kratom is fortified
                              with a concentrated extract, boasting an
                              extraordinary 5% total Mitragynine and 80% total
                              alkaloids. The essence is so powerfully condensed
                              that each sprinkle of powder holds the energies of
                              the mightiest Kratom leaves, selected under the
                              moonlit dance of celestial alignments.
                              <br />
                              <br />
                              Crafted for those who dare to delve into profound
                              realms of heightened sensation and acute
                              awareness, this blend requires respect for its
                              potency. Even the most experienced of Kratom users
                              approach with caution, and newcomers are advised
                              to start with but a whisper of powder to unlock
                              its mystical effects.
                              <br />
                              <br />
                              Each batch undergoes rigorous purification in our
                              state-of-the-art sanctum, ensuring that what
                              reaches you is nothing less than a pure,
                              unadulterated conduit of the Green Oracle’s power.
                              Embark on your journey with the Ultra Enhanced
                              Super Green Maeng Da and feel the ancient energies
                              of the forest course through you.
                            </Typography>
                          </>
                        )}

                        {product.color === "platinumTablets" && (
                          <>
                            <Typography
                              variant="h6"
                              style={{
                                color: "#424242", // Darker text for enhanced readability
                                fontSize: "1.25rem", // Appropriately large font size for easy reading
                                lineHeight: "1.6", // Optimal line height for better readability
                                textAlign: "left", // Text aligned to the left for a smoother reading experience
                                fontWeight: "normal", // Standard weight to maintain readability without being too bold
                                background: "rgba(255, 255, 255, 0.7)", // A semi-transparent white background for text emphasis
                                padding: "10px", // Padding to ensure text is comfortably distanced from the edges
                                borderRadius: "4px", // Softly rounded corners for a visually appealing presentation
                              }}
                            >
                              Mystic Essence - Platinum Kratom Extract Tablets
                            </Typography>
                            <Typography sx={{ color: "#424242" }}>
                              Unveil the pinnacle of Kratom potency with Mystic
                              Essence - Platinum Kratom Extract Tablets. Forged
                              in the crucibles of advanced alchemy, these
                              tablets are a marvel of purity and strength, each
                              infused with 65% Mitragynine and 80% total
                              alkaloids.
                            </Typography>
                            <ul
                              style={{ textAlign: "left", lineHeight: "1.6" }}
                            >
                              <li>
                                Crafted using only the highest quality Kratom
                                leaves, expertly extracted to achieve a product
                                unmatched in both strength and purity.
                              </li>
                              <li>
                                Each 50mg tablet delivers the power of 4 grams
                                of premium Kratom leaf, ensuring an experience
                                both profound and unparalleled.
                              </li>
                              <li>
                                Perfect for connoisseurs and novices alike,
                                these tablets promise to exceed expectations
                                with their incredible potency.
                              </li>
                              <li>
                                Embrace the ultimate Kratom experience with our
                                Platinum Kratom Extract Tablets, the strongest
                                and purest extract in the annals of history.
                              </li>
                            </ul>
                            <Typography
                              variant="body1"
                              style={{
                                color: "#424242", // Keeping the text color consistent for harmony
                                lineHeight: "1.6", // Matching line height for a uniform appearance
                                background: "rgba(255, 255, 255, 0.7)", // Maintaining the semi-transparent overlay for readability
                                padding: "10px", // Consistent padding for a neat and tidy layout
                                borderRadius: "4px", // Keeping the corners rounded for a cohesive look
                              }}
                            >
                              Ingredients: Microcrystalline Cellulose, Magnesium
                              Stearate, Silica Di Oxide, Di-calcium Phosphate,
                              Standardized Mitragyna Speciosa Extract.
                            </Typography>
                          </>
                        )}

                        {product.color === "extract80%" && (
                          <>
                            Step into the vault of ancient alchemy with Mystic
                            Essence - Platinum Kratom Extract. Hidden within the
                            depths of mystical forests, where time whispers
                            secrets of the universe, our most skilled herbal
                            sorcerers have unearthed the formula of
                            extraordinary potency. The Platinum Kratom Extract,
                            a pinnacle of purity, is not just an extract but a
                            distillation of powerful magical heritage.
                            <br />
                            <br />
                            This extract is the embodiment of mystical wisdom,
                            an essence so refined that it transcends the mere
                            color spectrum, appearing almost white. With 80%
                            total alkaloids, of which 65% is Mitragynine, this
                            concoction offers a symphony of the otherworldly
                            strength. Each gram of our extract is equivalent to
                            80 grams of select, highly potent Kratom leaves,
                            ensuring that every speck is packed with an
                            enchanting power.
                            <br />
                            <br />
                            Our Mystic Essence - Platinum Kratom Extract is
                            crafted for the true connoisseurs of Kratom, those
                            who seek to unlock the ancient energies and embrace
                            the profound spiritual experiences it offers. At
                            Mystics Leaf, we deliver not just an extract, but a
                            key to the ancient powers held within the sacred
                            leaves of Kratom.
                          </>
                        )}

                        {product.color === "gummy" && (
                          <>
                            Embark on a journey to the enchanted realms of
                            Kratom with the introduction of Sorcerer's Sweets -
                            Gummy Extracts. In the ancient woods, shrouded in
                            mystery and magic, the Elders of the Arcane crafted
                            potions of formidable potency. Among these mystical
                            concoctions, a unique creation stood unparalleled -
                            the Gummy Extracts, imbued with the essence of
                            potent Kratom.
                            <br />
                            <br />
                            These Gummy Extracts are not mere treats; they are
                            the culmination of age-old alchemy and the profound
                            knowledge of the forest's mystic guardians. Each
                            piece is a testament to the sorcerers' mastery over
                            the elements, containing 10mg of the purest
                            Mitragynine, extracted with precision from the heart
                            of the wilderness. The alchemists, in their wisdom,
                            have harnessed the vibrant essence of Kratom,
                            encapsulating its energy within the delectable form
                            of a gummy.
                            <br />
                            <br />
                            Sorcerer's Sweets - Gummy Extracts are an homage to
                            the legacy of the forest sorcerers, embodying the
                            harmony between the ancient mystique of Kratom and
                            the delightful allure of a sweet. These gummies are
                            crafted for those who seek to marvel at the wonders
                            of nature's alchemy, experiencing a touch of
                            enchantment with every encounter. At Mystics Leaf,
                            we invite you to delve into the lore of the arcane .
                          </>
                        )}

                        {product.color === "green" && (
                          <>
                            Dive deep into the history of Kratom, and you'll
                            discover the legend of the Celestial Clover Enhanced
                            - Super Green Maeng Da. In ancient times, mystical
                            seekers in the wild jungles of the rainforests
                            recognized certain Kratom leaves that shimmered with
                            a unique vibrancy. These were the Maeng Da leaves –
                            known to Thai workers and shamans alike for their
                            unparalleled potency.
                            <br />
                            <br />
                            Maeng Da, translated as "Pimp Grade", wasn't just
                            any leaf. It was a title, a mark of distinction,
                            reserved only for leaves picked from trees that
                            harbored the richest alkaloid content. As times
                            changed, this revered strain from the deep
                            rainforests found its way to today's Kratom
                            connoisseurs, becoming the go-to choice for those
                            seeking a potent energy booster before challenging
                            tasks or intense workouts.
                            <br />
                            <br />
                            At Mystics Leaf, our Celestial Clover Enhanced -
                            Super Green Maeng Da continues this tradition,
                            delivering the legendary potency that once empowered
                            ancient mystics on their spiritual quests.
                          </>
                        )}
                        {product.color === "red" && (
                          <>
                            Traverse the annals of Kratom lore, and you will
                            uncover the saga of Ruby Sorcerer's Serenity - Super
                            Red Maeng Da. In the epochs past, enigmatic adepts
                            in the dense jungles of the rainforests identified
                            unique Kratom leaves that radiated with an
                            extraordinary aura. These were the Maeng Da leaves –
                            esteemed by Thai laborers and mystics alike for
                            their unmatched strength.
                            <br />
                            <br />
                            Maeng Da, meaning "Pimp Grade," was not merely a
                            leaf. It signified a badge of honor, bestowed solely
                            on leaves plucked from trees that were abundant in
                            alkaloid richness. With the passage of time, this
                            venerated variant from the obscure rainforests made
                            its journey to the aficionados of today’s Kratom,
                            standing as the preferred selection for those in
                            pursuit of a robust energy surge prior to demanding
                            endeavors or vigorous exercise regimes.
                            <br />
                            <br />
                            At Mystics Leaf, our Ruby Sorcerer's Serenity -
                            Super Red Maeng Da perpetuates this legacy, offering
                            the storied vigor that historically equipped ancient
                            sorcerers on their mystical voyages.
                          </>
                        )}
                        {product.color === "white" && (
                          <>
                            Venture through the chronicles of Kratom, and you
                            shall encounter the tale of the Ivory Mystic's
                            Vision - Super White Maeng Da. In bygone eras, sages
                            in the heart of the untamed rainforests would seek
                            out the Kratom leaves that glowed with a celestial
                            luminescence. These were the Maeng Da leaves –
                            revered by the indigenous folk and seers for their
                            extraordinary clarity and focus.
                            <br />
                            <br />
                            Maeng Da, known as "Pimp Grade," was not just a mere
                            leaf. It was an accolade, a symbol of excellence,
                            designated exclusively for leaves harvested from
                            trees with a bountiful spectrum of alkaloids. As the
                            sands of time flowed, this esteemed strain from the
                            secluded rainforests ventured into the hands of
                            modern Kratom enthusiasts, securing its spot as the
                            quintessential choice for those seeking a crisp
                            surge of mental acuity before engaging in cerebral
                            activities or creative endeavors.
                            <br />
                            <br />
                            At Mystics Leaf, our Ivory Mystic's Vision - Super
                            White Maeng Da is a homage to this tradition,
                            channeling the historic purity and mental precision
                            that once guided the ancient mystics through their
                            intellectual explorations.
                          </>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {product.category === "powder" && (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                        <Typography>
                          <FontAwesomeIcon icon={faPalette} color={"#2e7d32"} />{" "}
                          Kratom's Vibrant Essence
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ backgroundColor: "#f1f8e9", padding: "16px" }}
                      >
                        <Typography
                          variant="h3"
                          style={{
                            color: "#424242", // Darker text for better contrast
                            fontSize: "1.25rem", // Larger font size for readability
                            lineHeight: "1.6", // Increased line height for readability
                            textAlign: "left", // Left align text for easier reading
                            fontWeight: "normal", // Use normal weight to avoid overly bold text
                            background: "rgba(255, 255, 255, 0.7)", // Semi-transparent white overlay
                            padding: "10px", // Padding to ensure text doesn't touch the edges
                            borderRadius: "4px", // Rounded corners for aesthetic preference
                          }}
                        >
                          {product.color === "green" && (
                            <>The Vibrancy of Green Veins! </>
                          )}
                          {product.color === "red" && (
                            <>The Mystique of Red Veins! </>
                          )}
                          {product.color === "white" && (
                            <>The Enchantment of White Veins! </>
                          )}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#424242", // Same dark color for consistency
                            lineHeight: "1.6", // Same line height for consistency
                            background: "rgba(255, 255, 255, 0.7)", // Same semi-transparent overlay
                            padding: "10px", // Same padding
                            borderRadius: "4px", // Same rounded corners
                          }}
                        >
                          {product.color === "green" && (
                            <>
                              {" "}
                              The vibrant green veins of our kratom leaves are
                              not just a sight to behold; they're a testament to
                              the potency and vitality of our Super Green Maeng
                              Da. Each leaf is a natural work of art, a
                              testament to nature's wonder, and a gift to those
                              seeking mystical energy.
                            </>
                          )}

                          {product.color === "red" && (
                            <>
                              The deep red veins of our kratom leaves are more
                              than a visual marvel; they signify the strength
                              and passion of our Super Red Maeng Da. Each leaf
                              is a symbol of nature's alchemy, a testament to
                              the earth's enchantments, and a boon for those in
                              search of mystical vigor and restorative power.
                            </>
                          )}

                          {product.color === "white" && (
                            <>
                              The pure white veins of our kratom leaves
                              represent the unyielding clarity and spirit of our
                              Super White Maeng Da. Each leaf is a masterpiece
                              of natural wonder, a testament to the mystical
                              arts, and a treasure for those pursuing a burst of
                              ethereal energy and enhanced focus.
                            </>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography>
                        <FontAwesomeIcon icon={faTruckFast} color={"#2e7d32"} />{" "}
                        Shipping
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: "#f1f8e9", padding: "16px" }}
                    >
                      <h3>Choose Your Mystic Delivery!</h3>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#424242",
                          lineHeight: "1.6",
                          background: "rgba(255, 255, 255, 0.7)",
                          padding: "10px",
                          borderRadius: "4px",
                        }}
                      >
                        At Mystics Leaf, we offer four enchanted shipping
                        options to best suit your needs:
                        <ul>
                          <li>
                            <strong>📍 Pickup:</strong> Retrieve your mystical
                            goods from our emissary at Downtown Toronto City
                            Hall. Available Saturdays and Sundays, 1 PM to 7 PM.
                            Place your order before{" "}
                            <strong style={{ color: "red" }}>2 PM</strong> for
                            same-day pickup. Price:{" "}
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Free
                            </span>
                            .
                          </li>
                          <li>
                            <strong>
                              🚀 Same Day Delivery (Toronto Only):
                            </strong>{" "}
                            Experience the mystic energy without delay. Order
                            before{" "}
                            <strong style={{ color: "red" }}>2 PM</strong> for
                            delivery today. Price:{" "}
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              $15
                            </span>
                            .
                          </li>
                          <li>
                            <strong>
                              🦅 Express Shipping (1-3 days with tracking):
                            </strong>{" "}
                            Your mystical leaf will swiftly find its way to you.
                            Orders placed before{" "}
                            <strong style={{ color: "red" }}>2 PM</strong> are
                            dispatched the same day. Orders of 500g or more
                            summon free shipping! Price:{" "}
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {weightSelected >= 500 ? "FREE" : "$10.00"}
                            </span>
                            .
                          </li>
                          <li>
                            <strong>🐢 Mail:</strong> For those who embrace a
                            leisurely journey, place your order before{" "}
                            <strong style={{ color: "red" }}>2 PM</strong> for
                            same-day dispatch. Price:{" "}
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Free
                            </span>
                            .
                          </li>
                        </ul>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#424242",
                            lineHeight: "1.4",
                            marginTop: "10px",
                          }}
                        >
                          <em>
                            Please note: Orders placed after{" "}
                            <strong style={{ color: "red" }}>2 PM</strong> will
                            be processed the next business day.
                          </em>
                        </Typography>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography>
                        <FontAwesomeIcon icon={faGavel} color={"#2e7d32"} />{" "}
                        Terms of Use
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: "#f1f8e9", padding: "16px" }}
                    >
                      <h3>Important Usage Guidelines</h3>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#424242", // Same dark color for consistency
                          lineHeight: "1.6", // Same line height for consistency
                          background: "rgba(255, 255, 255, 0.7)", // Same semi-transparent overlay
                          padding: "10px", // Same padding
                          borderRadius: "4px", // Same rounded corners
                        }}
                      >
                        Please carefully review the following terms regarding
                        the use of our products:
                        <ul>
                          <li>
                            <strong>Medical Claims:</strong> This product is not
                            intended to diagnose, treat, cure, or prevent any
                            disease or condition. We do not sell any products
                            with medical claims.
                          </li>
                          <li>
                            <strong>Usage Limitations:</strong> Our products are
                            not intended for use by pregnant or nursing women,
                            nor by anyone with medical conditions.
                          </li>
                          <li>
                            <strong>Interactions:</strong> Do not combine our
                            products with alcohol or any prescription
                            medications.
                          </li>
                          <li>
                            <strong>Age Restrictions:</strong> Our products are
                            not for sale to anyone under 21 years of age.
                          </li>
                        </ul>
                        Adherence to these terms is crucial for a safe
                        experience with our Mystics Leaf products. Ensure you
                        are informed and use responsibly.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color={"#2e7d32"}
                        />{" "}
                        Kratom Use Disclaimer
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ backgroundColor: "#f1f8e9", padding: "16px" }}
                    >
                      <h3>Important Kratom Use Information</h3>
                      <Typography>
                        While Kratom remains legal to own in Canada, Health
                        Canada has not approved it for human consumption. This
                        means:
                      </Typography>
                      <ul>
                        <li>
                          It is legal to possess Kratom, and individuals may use
                          it as they deem fit at their own discretion. However,
                          it is not approved for internal consumption.
                        </li>
                        <li>
                          Consumers are responsible for understanding and
                          adhering to local regulations and recommendations
                          regarding Kratom use.
                        </li>
                        <li>
                          Health Canada's research into Kratom's safety and
                          effects is ongoing. Until their studies conclude,
                          Kratom remains unauthorized for consumption.
                        </li>
                        <li>
                          Ensure you are well-informed about dosages, potential
                          effects, and use Kratom responsibly.
                        </li>
                      </ul>
                      <Typography>
                        We urge you to prioritize your safety and always adhere
                        to Health Canada's guidelines.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <div
            style={{
              position: "relative",
              backgroundColor: "#effadc",
              textAlign: "center",
              // height: "200px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{ padding: "10px" }}
              justifyContent="center" // horizontally center
              alignItems="center" // vertically center
            >
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src="/leafMystic.png"
                    alt="Mystic Image"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginTop: "10px",
                      animation: "float 2s ease-in-out infinite",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ height: "100%", padding: "20px" }}>
                  <Typography
                    variant="h3"
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: "15px",
                    }}
                    gutterBottom
                  >
                    Meet Lorian, the Celestial Clover Enchanter
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      color: "#555",
                      marginBottom: "10px",
                    }}
                    gutterBottom
                  >
                    A Legend from Ancient Forests
                  </Typography>

                  <Typography
                    paragraph
                    style={{ color: "#777", marginBottom: "15px" }}
                  >
                    Lorian, once known as Leaf Mystic, hails from ancient
                    forests where magic resides. There, he discovered the
                    celestial clover or the Super Green Maeng Da. An herb imbued
                    with cosmic energies.
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      color: "#555",
                      marginBottom: "10px",
                    }}
                    gutterBottom
                  >
                    Unlock Nature's Magic
                  </Typography>

                  <Typography
                    paragraph
                    style={{ color: "#777", marginBottom: "10px" }}
                  >
                    For Lorian, this wasn't just an herb:
                  </Typography>

                  <ul
                    style={{
                      listStyleType: "circle",
                      paddingLeft: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography
                      component="li"
                      style={{ color: "#777", marginBottom: "8px" }}
                    >
                      It was a key to unlocking magic.
                    </Typography>
                    <Typography
                      component="li"
                      style={{ color: "#777", marginBottom: "8px" }}
                    >
                      Drinking it unveiled a world of clarity.
                    </Typography>
                    <Typography
                      component="li"
                      style={{ color: "#777", marginBottom: "8px" }}
                    >
                      It strengthened his connection to nature's wonders.
                    </Typography>
                  </ul>

                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      color: "#555",
                      marginBottom: "10px",
                    }}
                    gutterBottom
                  >
                    Energize Your Day
                  </Typography>

                  <Typography
                    paragraph
                    style={{ color: "#777", marginBottom: "15px" }}
                  >
                    Seeking that extra energy boost? Lorian's potions harness
                    the power of the celestial clover to invigorate and awaken
                    your senses.
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      color: "#555",
                      marginBottom: "10px",
                    }}
                    gutterBottom
                  >
                    Find Your Calm
                  </Typography>

                  <Typography
                    paragraph
                    style={{ color: "#777", marginBottom: "10px" }}
                  >
                    Dive into tranquility, alleviate anxiety, and find peace.
                    Let Lorian's ancient discovery guide your way.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>

          <Box
            sx={{ mt: 4, borderTop: "1px solid #eee", pt: 2, padding: "12px" }}
          >
            <Typography variant="h5">Leave A Review</Typography>

            <Box
              sx={{
                mt: 3,
                border: "1px solid #eee",
                borderRadius: 5,
                p: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              <StarRating
                onRatingChange={(data) => {
                  setRating(data);
                  setRatingError(false); // Reset error when user selects a rating
                }}
                locked={false}
              />
              {ratingError && (
                <Typography color="error" sx={{ mt: 1 }}>
                  Please select a star rating.
                </Typography>
              )}

              <TextField
                fullWidth
                label="Username (optional)"
                variant="outlined"
                sx={{ mt: 2 }}
                value={userUsername} // New state to hold the user-entered username
                onChange={(e) => setUserUsername(e.target.value)}
                helperText="Enter a username if you prefer not to use your real name."
              />

              <TextField
                fullWidth
                label="Review Header"
                variant="outlined"
                required
                sx={{ mt: 2 }}
                value={userHeader}
                onChange={(e) => setUserHeader(e.target.value)}
              />

              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                required
                type="email"
                sx={{ mt: 2 }}
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />

              <TextField
                fullWidth
                multiline
                rows={4}
                label="Add a comment..."
                variant="outlined"
                required
                sx={{ mt: 2 }}
                value={userComment}
                onChange={(e) => setUserComment(e.target.value)}
              />
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Upload Images:
              </Typography>
              <Button
                variant="contained"
                component="label"
                color="primary"
                sx={{ mt: 1 }}
              >
                Choose Files
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={handleImageChange}
                />
              </Button>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                {imagePreviews.map((src, index) => (
                  <Grid item xs={4} sm={3} md={2} key={index}>
                    <Paper elevation={2} sx={{ padding: 0.5 }}>
                      <img
                        src={src}
                        alt={`Preview ${index}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, width: "100%" }}
                onClick={handleAddComment}
                disabled={
                  !userComment.trim() || !userHeader.trim() || !userEmail.trim()
                }
              >
                Post
              </Button>
            </Box>

            {comments.map((comment, index) => (
              <div style={{ margin: "13px" }}>
                <Comment
                  comment={comment}
                  onEdit={handleEditComment}
                  key={comment.timestamp}
                />
              </div>
            ))}
          </Box>

          {/* <Footer /> */}
        </div>
      )}
      {!product && <LoadingScreen />}
    </>
  );
}
