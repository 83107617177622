
import React, { useState, useEffect } from "react";
import { auth, firestore } from './services'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { collection, addDoc, doc, setDoc, Timestamp } from "firebase/firestore";
import { useNavigate, useParams, redirect,useLocation } from "react-router-dom";
import { Typography, Button ,Divider} from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import firebase from "firebase/compat/app"
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import DiscountIcon from '@mui/icons-material/LocalOffer';
import './firebaseui-styling.css'; // Path to your custom Firebase UI CSS file
import { logCustomEvent } from "./analyticsLogger.js";

export default function FirebaseLoginComponent(props) {
    const { pathname } = useLocation();
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    const navigation = useNavigate();
    const [navigateTo, setNavigateTo] = useState(pathname);

    useEffect(() => {
        if(pathname === '/sampleForm'){
           setNavigateTo(pathname)
        }
        else if(pathname === '/cart'){
            setNavigateTo('/checkout')
        }
    }, [pathname]);
    var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: async function (authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                const storedAffiliateLink = localStorage.getItem("affiliateLink");
                // await addDoc(collection(firestore, "sellers", userId, 'affiliates'),storedAffiliateLink );
                // navigation('/dashboard/viewWigs')

                // sessionStorage.setItem("uid", uid);
                // sessionStorage.setItem("username", user.displayName || "");

                return true;
            },
            uiShown: function () {
                // The widget is rendered.
                // Hide the loader.
                document.getElementById('loader').style.display = 'none';
            }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: navigateTo,
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true,
                fullLabel: "Continue With Email",
                
            },
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                customParameters: {
                    // Forces account selection even when one account
                    // is available.
                    prompt: 'select_account'
                },
                fullLabel: "Continue with Google",
                scope:['profile','openid','email']
                    
                

            },
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
    };

    useEffect(() => {
        ui.start('#firebaseui-auth-container', uiConfig);


    },[])

    
    return (
        <Modal
            {...props}
            size="md" // Changed from 'lg' to 'md' for a more compact design
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sign Up/Sign In
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: '#4CAF50' }}>
                        Join Mystics Leaf and enjoy:
                    </Typography>
                    <Divider style={{ margin: '20px 0', backgroundColor: '#4CAF50' }} />
                    <Typography variant="body1" gutterBottom>
                        <LoyaltyIcon color="action" /> Earn free Kratom with each purchase
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <TrackChangesIcon color="action" /> Track your order in real-time
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <DiscountIcon color="action" /> Access special members-only discounts
                    </Typography>
                    <Divider style={{ margin: '20px 0', backgroundColor: '#4CAF50' }} />
                    {/* <Button
                        variant="contained"
                        color="error"
                        startIcon={<EmailIcon />}
                        style={{ marginBottom: '10px', backgroundColor: '#4CAF50', width: '80%', maxWidth: '300px' }}
                    >
                        Continue With Email
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<GoogleIcon />}
                        style={{ backgroundColor: '#4285F4', width: '80%', maxWidth: '300px' }}
                    >
                        Continue with Google
                    </Button> */}
                </div>
                <div id="firebaseui-auth-container" ></div>
                <div id="loader">Loading...</div>
                <div style={{ textAlign: 'center' }}>
      <Button
          variant="contained"
          style={{ backgroundColor: 'gray', width: '80%', maxWidth: '300px', margin: '10px auto' }}
          onClick={() => {
            props.onHide()
            logCustomEvent('screen_view', {
                firebase_screen: 'Modal_SignUp',
                action: 'user_continue_as_guest',
                
      
              });
            // navigation(navigateTo)
          }}
      >
          Continue As A Guest
      </Button>
  </div>
                
            </Modal.Body>
        </Modal>
    );

}