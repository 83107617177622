import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const ScrollToTop = () => {
  const { pathname } = useLocation();

  


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
const theme = createTheme({
  typography: {
    fontFamily: 'jost, Arial, sans-serif',
    // h1: {
    //   fontFamily: 'Young Serif, Arial, sans-serif',
    //   fontWeight: 300,
    //   fontSize: '3.75rem',
    //   lineHeight: 1.2,
    //   letterSpacing: '-0.00833em',
    //   // Any other styles you want to override
    // },
    // Repeat for other typography variants as needed
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}> 
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
