import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Box, Typography, Paper } from "@mui/material";
function BlogCarousel({ blogs }) {
  const [sortedBlogs, setSortedBlogs] = useState([]);

  useEffect(() => {
    // Sort blogs by publishedDate in descending order
    const sorted = blogs.sort(
      (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
    );
    setSortedBlogs(sorted);
  }, [blogs]); // Re-sort when blogs data changes

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth="lg"
      style={{ padding: "2rem 0", marginBottom: "10px",padding:'30px' }}
    >
      {/* Introductory Text */}
      <Typography
        variant="h4"
        style={{ textAlign: "center", marginBottom: "20px", color: "white" }}
      >
        Your Ultimate Online Kratom Buying Guide
      </Typography>
      <Typography
        variant="body1"
        style={{ textAlign: "center", marginBottom: "2rem", color: "white" }}
      >
        Embark on a streamlined and enlightened path to purchasing kratom online
        with our extensive kratom resource center. Whether you're deeply
        familiar with kratom or just beginning to discover its potential, our
        meticulously assembled guides and blog posts offer critical knowledge on
        navigating online kratom purchases, selecting the ideal strain, and
        identifying premium kratom products. Our diligent research ensures you
        have the information needed to make well-informed decisions and delve
        into the variety of strains at your disposal. Dive in now to uncover the
        kratom solution that best matches your requirements.
      </Typography>

      {/* Blog Carousel */}
      <Slider {...settings}>
        {sortedBlogs.map((blog) => (
          <div key={blog.id} style={{ padding: "0 15px" }}>
            <Paper
              style={{
                position: "relative",
                height: "250px",
                backgroundSize: "cover",
                backgroundImage: `url(${blog.thumbnail})`,
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  padding: "10px",
                }}
              >
                <Typography variant="h6">{blog.title}</Typography>
                <Typography variant="body2">
                  {new Date(blog.publishedDate).toLocaleDateString()}
                </Typography>
              </Box>
            </Paper>
          </div>
        ))}
      </Slider>
    </Container>
  );
}

export default BlogCarousel;
