// analytics.js
import { analytics } from "./services.js";
import { logEvent } from "firebase/analytics";

/**
 * Custom analytics event logger.
 * Ensures that all object properties are converted to strings
 * before logging, as Firebase Analytics expects event parameters
 * to be strings or numbers.
 * @param {string} eventName - The name of the event to log.
 * @param {object} eventParams - Optional parameters of the event.
 */
export function logCustomEvent(eventName, eventParams = {}) {
    try {
        // Serialize each property of the eventParams object to ensure they are compatible with Firebase
        const serializedParams = {};
        for (const key in eventParams) {
            const value = eventParams[key];
            // Check if the value is an array of items, which Firebase Analytics can accept
            if (Array.isArray(value) && value.every(item => typeof item === 'object')) {
                serializedParams[key] = value;
            } else {
                serializedParams[key] = typeof value === 'object' ? JSON.stringify(value) : value;
            }
        }

        // Call logEvent with the analytics instance, event name, and serialized parameters
        logEvent(analytics, eventName, serializedParams);
    } catch (error) {
        console.error("Failed to log event:", eventName, error);
    }
}
