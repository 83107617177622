import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Navbar from '../shared/Navbar'
import './Blogs.css';

const Blog = ({ title, excerpt, thumbnail, publishedDate, author, slug,tags }) => {
  const navigate = useNavigate();
  
  // Function to handle the click event
  const navigateToPost = () => {
    navigate(`/blog/${slug}`); // Use the slug for navigation
  };

  return (<> 
  
    <article className="blog" onClick={navigateToPost}>
      <img src={thumbnail} alt={title} className="blog-thumbnail" />
      <div className="blog-content">
        <h2 className="blog-title">{title}</h2>
        <p className="blog-excerpt">{excerpt}</p>
        <p className="blog-info">
          <span className="blog-author">{author}</span> - <time className="blog-date">{publishedDate}</time>
        </p>
        {tags&&<p className="blog-info">
          <span className="blog-author">Tags: {tags}</span> 
        </p>}
        <Button className="blog-read-more">Read More</Button>
      </div>
    </article>
    </>
  );
};

export default Blog;
