// import { getProduct } from "../models/product.server.js";
import { firestore, storage, analytics } from "./services.js";
import { writeBatch, collection, getDocs, doc, getDoc, setDoc, arrayUnion, increment, runTransaction, updateDoc, onSnapshot, where, query, addDoc } from "firebase/firestore";
import moment from "moment";
import { logEvent } from "firebase/analytics";
import { logCustomEvent } from "./analyticsLogger.js";

function mapCartToItems(cart) {
    return cart.map((items) => {
        return {
            productImage: items?.product?.productImages[0],
            name: items.product.title + ' - ' + items.variant.title,
            price: items.variant.price,
            quantity: items.quantity,
            slug: items.product.id,
        };
    });
}



const firestoreService = {
    async getProducts() {
        // Create a query against the "Products" collection.
        // This query will only fetch documents where availableForSale is true.
        const q = query(collection(firestore, "Products"), where("availableForSale", "==", true));

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Map through the documents and return an array of product data with the document ID included
        const products = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        return products;
    },



    async getProduct(id) {
        const docRef = doc(firestore, "Products", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { ...docSnap.data(), id: docSnap.id };
        } else {
            return null;
        }

    },
    async updateCart(uid, cart) {
        const docRef = doc(firestore, "Users", uid);
        await updateDoc(docRef, { cart: cart });
    },

    async addToCart(product, variant, quantity, uid) {
        logCustomEvent('add_to_cart', {
            product: product,
            variant: variant,
            quantity: quantity,
            uid: uid
        });
        // sessionStorage.setItem("cartItems", JSON.stringify({ product: product, variant: variant, quantity: quantity }));
        const docRef = doc(firestore, "Users", uid);
        setDoc(docRef, { cart: arrayUnion({ product: product, variant: variant, quantity: quantity }) }, { merge: true });

    },
    async createOrder(uid, order) {


        // Get a new write batch
        // const writeBatch = batch(firestore);
        const batch = writeBatch(firestore)

        // Document reference for the user
        const userDocRef = doc(firestore, "Users", uid);

        // Document reference for the new order in the Orders collection.
        // Assuming 'Orders' collection exists, and we want to add a new document in it.
        const orderDocRef = doc(collection(firestore, "Orders"));
        logCustomEvent('create_order', {
            order: order,
            uid: uid
        });
        // Update the user's orders
        batch.set(userDocRef, { orders: arrayUnion(order) }, { merge: true });

        // Add new order to the Orders collection
        batch.set(orderDocRef, order);
        await batch.commit();

        // Clear the cart array
        await updateDoc(userDocRef, {
            cart: []
        });

        // Commit the batch
        return orderDocRef.id;
    },
    // The function to set up real-time listener
    async setupRealtimeCartListener(uid, setCartItems, setCartItemsLength) {
        const docRef = doc(firestore, "Users", uid);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                const cart = doc.data().cart;
                const newCartItems = mapCartToItems(cart);
                setCartItems(newCartItems);

                if (setCartItemsLength) {
                    setCartItemsLength(newCartItems.length);
                }

            } else {
                // Handle the case where the document does not exist
            }
        });
        // Returning the unsubscribe function lets you clean up the listener when needed
        return unsubscribe;
    },
    async getCart(uid) {
        const docRef = doc(firestore, "Users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const cart = docSnap.data().cart || [];
            sessionStorage.setItem("cartItems", JSON.stringify(cart));
            // Consolidate cart items based on SKU
            let consolidatedCart = {};
            cart.forEach(item => {
                let sku = item.variant.sku;
                if (consolidatedCart[sku]) {
                    consolidatedCart[sku].quantity += item.quantity;
                } else {
                    consolidatedCart[sku] = {
                        productImage: item.product.productImages[0],
                        name: `${item.product.title} - ${item.variant.title}`,
                        price: item.variant.price,
                        quantity: item.quantity,
                        slug: item.product.slug,
                        sku: item.variant.sku,
                    };
                }
            });
    
            return Object.values(consolidatedCart);
        } else {
            return null;
        }
    }
    ,
    async removeFromCart(itemToRemove, uid) {
        const docRef = doc(firestore, "Users", uid);
    
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();
            const cart = userData.cart || [];

            // Find and remove the item from the cart array using 'sku'
            const newCart = cart.filter(item => item.variant.sku !== itemToRemove.sku);
    
            await updateDoc(docRef, { cart: newCart });
            return newCart;
        }
    }
    
    
    ,
    async createSampleOrder(customerDetails, uid) {
        const docRef = doc(firestore, "sampleOrders", uid);


        // Use a transaction to ensure atomicity
        await runTransaction(firestore, async (transaction) => {
            const docSnapshot = await transaction.get(docRef);

            if (docSnapshot.exists()) {
                // If document exists, increment sampleRequest and update modifiedAt
                transaction.update(docRef, {
                    sampleRequest: increment(1),
                    modifiedAt: moment().format('LLLL'),
                });
            } else {
                // If document does not exist, set all fields including createdAt
                transaction.set(docRef, {
                    sampleRequest: increment(1),
                    customerDetails: customerDetails,
                    orderFullfilled: false,
                    createdAt: moment().format('LLLL'),
                    modifiedAt: moment().format('LLLL'),
                });
            }
        });
    },
    async getUser(uid) {
        const docRef = doc(firestore, "Users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return null;
        }
    },
    async updateUser(uid, data) {
        delete data.cart;
        delete data.orders;
        const docRef = doc(firestore, "Users", uid);
        await updateDoc(docRef, { userData: data });
    },
    async getDiscountCode(code) {
        const docRef = doc(firestore, "Discounts", code);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          return null;
        }
      },

    async incrementBlogView(blogId, uid) {
        const blogRef = doc(firestore, "Blogs", blogId);
  
        await runTransaction(firestore, async (transaction) => {
          const blogDoc = await transaction.get(blogRef);
          if (!blogDoc.exists()) {
            throw "Document does not exist!";
          }
          
          const data = blogDoc.data();
          const views = data.views || [];
          // Check if the UID is already in the views array
          if (!views.includes(uid)) {
            // If not, add the UID to the array and increment the view count
            transaction.update(blogRef, {
              views: arrayUnion(uid),
              viewCount: increment(1)
            });
          }
        });
      },
    // Function to add a new blog
    async addBlog(blogData) {
        try {
            // Generate a slug from the blog title
            const slug = createSlug(blogData.title);

            // Check if a blog with the same slug already exists
            const q = query(collection(firestore, "Blogs"), where("slug", "==", slug));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Handle the case where a blog with the same slug already exists
                console.error("A blog with the same slug already exists.");
                return null;
            }

            // Add the new blog document with the generated slug
            const docRef = await addDoc(collection(firestore, "Blogs"), {
                ...blogData,
                slug, // Include the slug in the document
                createdAt: new Date(), // Include a timestamp
                views:[],
                viewCount: 0
            });
            return docRef.id; // Returns the newly created blog ID
        } catch (error) {
            console.error("Error adding blog: ", error);
        }
    },


    // Function to get all blogs
    async getBlogs() {
        try {
            const querySnapshot = await getDocs(collection(firestore, "Blogs"));
            return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
            console.error("Error getting blogs: ", error);
        }
    },

    // Function to view a single blog by ID
    async viewBlog(blogId) {
        try {
            const docRef = doc(firestore, "Blogs", blogId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return { ...docSnap.data(), id: docSnap.id };
            } else {
                // Handle the case where the blog does not exist
                console.log("No such blog!");
            }
        } catch (error) {
            console.error("Error viewing blog: ", error);
        }
    },
    async viewBlogBySlug(slug) {
        try {
            const q = query(collection(firestore, "Blogs"), where("slug", "==", slug));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                // Handle the case where the blog does not exist
                console.log("No such blog!");
                return null;
            }

            // Assuming slugs are unique, there should only be one matching document
            const blogDoc = querySnapshot.docs[0];
            return { ...blogDoc.data(), id: blogDoc.id };
        } catch (error) {
            console.error("Error viewing blog by slug: ", error);
        }
    },


    async updateUserData(uid, data) {

        const docRef = doc(firestore, "Users", uid);
        await updateDoc(docRef, data);
    },
    getCommentsForProduct(productId) {
        const commentsRef = collection(firestore, "Comments");
        const q = query(commentsRef, where("productId", "==", productId), where("comment.commentCanBeDisplayed", "==", true));
        return getDocs(q);
    },

    addCommentForProduct(uid, productId, comment) {
        logCustomEvent('user_added_comment', {
            productId: productId,
            uid: uid

        });
        return setDoc(doc(collection(firestore, "Comments")), {
            productId: productId,
            comment: comment,
            uid
        });
    },
    async getOrders() {
        // Create a query against the "Orders" collection.
        const q = query(collection(firestore, "Orders"));

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Map through the documents and return an array of order data with the document ID included
        const orders = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        return orders;
    },
    async updateOrder(orderId, updatedOrderData) {
        const orderDocRef = doc(firestore, "Orders", orderId);
    
        try {
          await updateDoc(orderDocRef,{ ...updatedOrderData,updatedLast:moment().utc().unix()});
          console.log("Order updated successfully:", orderId);
          return true;
        } catch (error) {
          console.error("Error updating order:", error);
          return false;
        }
      },
      async getUserOrders(uid) {
        // Create a query against the "Orders" collection where the 'uid' field matches the user's UID
        const q = query(collection(firestore, "Orders"), where("uid", "==", uid));
    
        try {
          // Execute the query
          const querySnapshot = await getDocs(q);
          // Map through the documents and return an array of order data with the document ID included
          return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        } catch (error) {
          console.error("Error fetching user orders:", error);
          throw new Error('Unable to fetch user orders.');
        }
      },


}

function createSlug(title) {
    return title
        .toLowerCase()
        .replace(/[^\w\s-]/g, '') // Remove non-alphanumeric characters
        .trim()
        .replace(/[\s_-]+/g, '-') // Replace spaces and underscores with hyphens
        .replace(/^-+|-+$/g, ''); // Trim hyphens from start and end
}

export default firestoreService; 