import React, { useState, useEffect } from "react";
import OrderModal from "../../components/shared/OrderModal";
import firestoreService from "../../services/firestoreServices";
import moment from "moment";

function Orders({ accountOrders }) {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const userId = sessionStorage.getItem('uid');
    console.log('orders.jsx', userId);
    firestoreService.getUserOrders(userId).then((orders) => {
      setOrders(orders);
    }
    );
    // setOrders(accountOrders);
  }, []);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div style={{ padding: "1em" }}>
      <h2>Your Orders</h2>
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          maxHeight: "400px", // Set a fixed height for the container (You can adjust the value as per your requirements)
          overflowY: "auto", // Enable vertical scrolling when content exceeds container's height
        }}
      >
        {orders.map((order) => (
          <li
            key={order.id}
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
              padding: "1em",
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onClick={() => handleOrderClick(order)}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#f5f5f5")
            }
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
          >
            <img
              src={order.cartItems[0].productImage}
              alt="product"
              style={{
                width: "100px",
                height: "100px",
                marginRight: "1em",
              }}
            />
            <span>
              Order from {moment.unix(order.unixTime).format("dddd, MMMM Do YYYY")} - Status:{" "}
              {order.status.value?order.status.label:order.status}
            </span>
          </li>
        ))}
      </ul>

      {selectedOrder && (
        <OrderModal
          order={selectedOrder}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

export default Orders;
