// Loading Screen Component
const LoadingScreen = () => {
    return (
      <div style={loadingContainerStyle}>
        <img
          src="/KratomLeaf.png"
          alt="Loading"
          style={loadingImageStyle}
        />
        <p style={loadingTextStyle}>Loading...</p>
      </div>
    );
  }
  
  // Styles
  const loadingContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // this takes the full height of the viewport
  };
  
  const loadingImageStyle = {
    height: '40px',
    width: '22px',
    animation: 'spin 2s linear infinite', // this applies the spinning animation
  };
  
  const loadingTextStyle = {
    marginTop: '10px',
    fontSize: '1.5em',
    color: '#333',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // This is an example of a professional-looking font stack
  };

  export default LoadingScreen;