// import { Link, useLocation, useNavigate } from "@remix-run/react";
import {
  Outlet,
  Link,
  useNavigate,
  useMatch,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../services/services";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import firestoreService from "../../services/firestoreServices";
import { logCustomEvent } from "../../services/analyticsLogger";

export default function Navbar() {
  const navigate = useNavigate();
  const drawerItems = [
    { name: "MYSTICS LEAF", link: "/home", isTitle: true },
    { name: "Home", link: "/home" },
    { name: "Shop", link: "/shop" },
    { name: "FAQ", link: "/faq" },
    { name: "Blogs", link: "/blogs" },
    { name: "Account", link: "/account" },
    { name: "Cart", link: "/cart" },
  ];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [cartItems, setCartItems] = useState(0);
  const [userId, setUserId] = useState("");
  const [everyTimeBanner, setEveryTimeBanner] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const location = useLocation();
  const isCheckout = location.pathname === "/checkout"; // Check if the current route is the checkout

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    if (uid) {
      setUserId(uid);
    }

    const displayAnnouncementBanner = sessionStorage.getItem(
      "displayAnnouncementBanner"
    );
    if (displayAnnouncementBanner || displayAnnouncementBanner === false) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }

    const isUserSignedIn = sessionStorage.getItem("isUserSignedIn");
    const displayEveryTimeBanner = sessionStorage.getItem(
      "displayEveryTimeBanner"
    );

    if (displayEveryTimeBanner || displayEveryTimeBanner === false) {
      setEveryTimeBanner(false);
    } else {
      setEveryTimeBanner(true);
    }

    if (isUserSignedIn === "true") {
      setIsUserSignedIn(true);
    } else {
      setIsUserSignedIn(false);
    }
  }, []);

  const handleBannerClose = () => {
    setShowBanner(false);
    sessionStorage.setItem("displayAnnouncementBanner", false);
  };

  // Announcement Banner
  const AnnouncementBanner = () => (
    <>
      <div
        style={{
          backgroundColor: "#a8d5ba",
          color: "rgb(62, 42, 16)",
          padding: "10px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Typography variant="h6" component="p">
          🌿 Sign up today and receive a mystical 10% off your next Mystics Leaf
          order, unleashing the magic of Kratom at a discounted price! 🌟
        </Typography>
        <IconButton
          onClick={handleBannerClose}
          style={{ position: "absolute", right: 10, top: 5 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </>
  );

  // Announcement Banner
  const AnnouncementBannerEveryTime = () => (
    <>
      <div
        style={{
          backgroundColor: "#a8d5ba",
          color: "rgb(62, 42, 16)",
          padding: "10px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Typography variant="h6" component="p">
          🌟 Unlock a magical bonus! Receive a FREE mystical gummy extract with
          every first order or any order over 500g! 🌿 Experience the enchanted
          powers of Kratom with an extra treat from Mystics Leaf. ✨
        </Typography>
        <IconButton
          onClick={() => {
            setEveryTimeBanner(false);
            sessionStorage.setItem("displayEveryTimeBanner", false);
          }}
          style={{ position: "absolute", right: 10, top: 5 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </>
  );

  // useEffect(() => {
  //   const uid = sessionStorage.getItem("uid");
  //   if(userId){
  //  firestoreService.getCart(uid).then((tempItems) => {

  //   setCartItems(tempItems.length)

  // })}

  // }, [userId]);

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");
    if (uid) {
      const unsubscribe = firestoreService.setupRealtimeCartListener(
        uid,
        (data) => {},
        setCartItems
      );

      // Cleanup the listener when the component unmounts
      return () => unsubscribe;
    }
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       const uid = user.uid;
  //       setUserId(uid)
  //       sessionStorage.setItem("uid", uid);
  //       sessionStorage.setItem("username", user.displayName || "");
  //       sessionStorage.setItem("email", user.email || "");

  //       if (!user.isAnonymous) {

  //         sessionStorage.setItem("isUserSignedIn", true);
  //         const cartItems = sessionStorage.getItem("cartItems");
  //         if (cartItems) {
  //           console.log('cartItems', cartItems)

  //           const cartJson = JSON.parse(cartItems);
  //           console.log('cartJson', cartJson)
  //           firestoreService.updateCart(uid, cartJson);
  //           sessionStorage.removeItem("cartItems");

  //         }

  //       } else {
  //         sessionStorage.setItem("isUserSignedIn", false);
  //       }
  //       setUserId(uid);

  //       // ...
  //     } else {
  //       // User is signed out
  //       // ...
  //       console.log('user is signed out')
  //       sessionStorage.setItem("isUserSignedIn", false);

  //       // signInAnonymously(auth)
  //       //   .then(() => {
  //       //     sessionStorage.setItem("isUserSignedIn", false);
  //       //     // Signed in..
  //       //   })
  //       //   .catch((error) => {
  //       //     sessionStorage.setItem("isUserSignedIn", false);
  //       //     const errorCode = error.code;
  //       //     const errorMessage = error.message;
  //       //     // ...
  //       //   });
  //     }

  //   });

  // }, []);

  return (
    <div>
      {/* {!isUserSignedIn && showBanner && <AnnouncementBanner />} */}
      {/* {everyTimeBanner && <AnnouncementBannerEveryTime />} */}
      <AppBar position="static" sx={{ background: "#f3fff9" }}>
        <Toolbar>
          {!isCheckout && isMobile && (
            <IconButton
              edge="start" // Adjust edge property
              color="#3A5F0B"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box
            sx={{
              flexGrow: 12,
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "flex-start",
            }}
          >
            <div
              onClick={() => {
                navigate("/home");
                logCustomEvent("navigation", {
                  location: "home_by_logo",
                  uid: userId,
                });
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Added to center horizontally
                cursor: "pointer",
              }} // This will align the logo and the text vertically
            >
              <div 
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Added to center horizontally
                cursor: "pointer",
              }}
              >

                <img
                src={"/KratomLeaf.png"}
                alt="Icon"
                style={{ height: 40, width: 22,marginLeft:'24px'  }} // Adjusted sizes for better alignment
              />
              </div>
              
              {!isMobile && (
                <Typography
                  variant="h5"
                  color={"black"}
                  style={{
                    cursor: "pointer",
                    textAlign: isMobile ? "center" : "",
                  }}
                >
                  MYSTICS LEAF
                </Typography>
              )}
            </div>
          </Box>

          {!isCheckout && isMobile ? (
            <><IconButton
            aria-label="cart"
            onClick={() => {
              navigate("/cart");
              logCustomEvent("navigation", {
                location: "to_cart",
                uid: userId,
              });
            }}
          >
            <StyledBadge
              badgeContent={cartItems}
              color="secondary"
            >
              <ShoppingCartIcon fontSize="large" />
            </StyledBadge>
          </IconButton></>
          ) : (
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
            >
              {!isCheckout &&
                drawerItems.map((item, index) => {
                  const isActive = location.pathname === item.link;
                  if (!item.isTitle) {
                    return (
                      <>
                        {item.name !== "Cart" && item.name !== "Users" && (
                          <Link
                            to={item.link}
                            key={index}
                            style={{
                              textDecoration: "none",
                              color: isActive ? "#3A5F0B" : "#273444",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ margin: "0 10px", marginTop: "13px" }}
                            >
                              {item.name.toUpperCase()}
                            </Typography>
                          </Link>
                        )}
                        {item.name === "Cart" && (
                          <IconButton
                            aria-label="cart"
                            onClick={() => {
                              navigate("/cart");
                              logCustomEvent("navigation", {
                                location: "to_cart",
                                uid: userId,
                              });
                            }}
                          >
                            <StyledBadge
                              badgeContent={cartItems}
                              color="secondary"
                            >
                              <ShoppingCartIcon fontSize="large" />
                            </StyledBadge>
                          </IconButton>
                        )}
                        {/* {item.name === "Account" && (
                          <IconButton
                            aria-label="user"
                            onClick={() => {
                              navigate("/account");
                            }}
                          >
                            <StyledBadge color="secondary">
                              <PersonIcon fontSize="large" />
                            </StyledBadge>
                          </IconButton>
                        )} */}
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {!isCheckout && isMobile && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            style: { width: "80%", maxWidth: "300px" }, // This will make sure the drawer doesn't take the whole width on tablets or bigger screens.
          }}
        >
          <List>
            {drawerItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  navigate(item.link);

                  logCustomEvent("navigation", {
                    location: "to_" + item.link.replace("/", " "),
                    uid: userId,
                  });
                }}
              >
                <ListItemText
                  primary={item.name}
                  style={
                    item.isTitle
                      ? {
                          fontWeight: "bold",
                          fontSize: "20px",
                          textAlign: "center",
                          color: "#3A5F0B",
                        }
                      : {}
                  }
                />
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </div>
  );
}
