import React from 'react';
import { Card, CardContent, Typography, Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import "./Referrals.css";

const Referrals = ({ userData }) => {
  const referralRewards = userData?.referralRewardsEarned || 0;
  const referralCode = userData?.referralCode || '';
  const referralLink = `${window.location.origin}?ref=${referralCode}`; // Replace with your site's base URL if needed
  const referralsMade = userData?.referralsMade?.length || 0;

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.success('Copied to clipboard!');
    }, () => {
      toast.error('Failed to copy.');
    });
  };

  const shareReferralCode = () => {
    copyToClipboard(referralCode);
  };

  const shareReferralLink = () => {
    copyToClipboard(referralLink);
  };


  return (
    <div className="referrals-section">
      <Typography variant="h4" component="h2" className="referrals-title">
        Referrals
      </Typography>
      <Typography variant="h6" component="h2" className="referrals-title">
      Enchant Your Circle! Introduce three friends to Mystics Leaf, and as a token of gratitude, receive 100 grams of our magical Kratom. Share the secret, enrich the journey!
      </Typography>
      <Card style={{marginTop:'10px'}} className="reward-card">
        <CardContent>
          <Typography color="textSecondary">
            Your Referral Code:
          </Typography>
          <Typography variant="h5" className="referral-code">
            {referralCode}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={shareReferralCode}
            className="share-referral-button"
          >
            Copy Code
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ContentCopyIcon />}
            onClick={shareReferralLink}
            className="share-referral-button"
          >
            Copy Link
          </Button>
        </CardContent>
      </Card>
      <Card className="reward-card">
        <CardContent>
          <Typography color="textSecondary">
            Referred Count:
          </Typography>
          <Typography variant="h5">
            {referralsMade}
          </Typography>
        </CardContent>
      </Card>
      <Card className="reward-card">
        <CardContent>
          <Typography color="textSecondary">
            Rewards Earned:
          </Typography>
          <Typography variant="h5">
            {referralRewards}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Referrals;
