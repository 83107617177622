import React from "react";
import Navbar from "../shared/Navbar";
// import Footer from "../Footer";
import {
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import {
  Outlet,
  Link,
  useNavigate,
  useMatch,
  useLocation,
  useParams,
} from "react-router-dom";
import Chip from "@mui/material/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faSeedling } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import EcoIcon from '@mui/icons-material/Eco';
import VerifiedIcon from "@mui/icons-material/Verified";
import StorefrontIcon from "@mui/icons-material/Storefront";
import "./Homepage.css"; // Importing our custom CSS
import { logCustomEvent } from "../../services/analyticsLogger";
import firestoreService from "../../services/firestoreServices";
import { useEffect, useState } from "react";
import { getAnalytics, setUserId } from "firebase/analytics";
import { Helmet } from "react-helmet"; // You might need to install the react-helmet package
import TopBanner from "./TopBanner.js";
import FeatureSection from "./FeatureSection.js";
import BlogCarousel from "../blogs/BlogCarousel.js";
export default function HomePage() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogData = await firestoreService.getBlogs();
        setBlogs(blogData);
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const uid = sessionStorage.getItem("uid");

    const analytics = getAnalytics();
    setUserId(analytics, uid);
    logCustomEvent("screen_view", {
      firebase_screen: "homepage",
      uid: uid,
    });
  }, []);

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const styles = {
    containerMobile: {
      padding: "20px",
    },
    headingMobile: {
      fontSize: "1.5rem",
    },
    subHeadingMobile: {
      fontSize: "0.9rem",
    },
  };

  const blockquoteStyle = {
    fontStyle: "italic",
    margin: "20px 0",
  };

  const homepageStructuredData = {
    "@context": "http://schema.org",
    "@type": "Store",
    name: "Mystics Leaf",
    url: "https://mysticsleaf.com",
    address: {
      "@type": "PostalAddress",
      streetAddress: "123 Mystic Street",
      addressLocality: "Toronto",
      addressRegion: "ON",
      postalCode: "M1M 1M1",
      addressCountry: "CA",
    },
    description:
      "Mystics Leaf - Top supplier of Kratom in the GTA, offering premium quality Kratom leaves sourced from Southeast Asia.",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        opens: "00:00",
        closes: "23:59",
      },
    ],
    geo: {
      "@type": "GeoCoordinates",
      latitude: 43.654972,
      longitude: -79.403555,
    },
    sameAs: ["https://www.instagram.com/mysticsleaf"],
    areaServed: [
      "Greater Toronto Area",
      "Pickering",
      "Ajax",
      "Scarborough",
      "Mississauga",
      "Hamilton",
      "North York",
      "Oshawa",
      "Bowmanville",
      "Ontario",
      "Canada",
    ],
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Conditional styles for the container based on the isMobile state
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px",
    backgroundColor: "#e8f5e9",
    textAlign: "center",
  };

  // Style for the sections container
  const sectionsContainerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: isMobile ? "20px" : "40px",
  };

  // Style for each delivery option section
  const sectionStyle = {
    margin: isMobile ? "10px 0" : "0 10px",
    flexBasis: isMobile ? "100%" : "auto",
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="home-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage:
          "url('https://sulawesikratom.b-cdn.net/wp-content/uploads/2021/02/pattern_med_03_med_08.png')",
        backgroundRepeat: "repeat", // This will tile the pattern across the div
        backgroundColor: "#275c53", // Ensures the pattern applies on a transparent background
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(homepageStructuredData)}
        </script>
      </Helmet>
      <Navbar />
      {/* <TopBanner /> */}
      <div style={{ padding: "20px" }}>
        <Typography
          variant={isMobile ? "h4" : "h2"}
          gutterBottom
          style={{
            color: "white",
            textAlign: isMobile ? "center" : "center",
          }}
        >
          Mystics Leaf - Nature's Best Kept Secret
        </Typography>
        <Typography
          variant={isMobile ? "subtitle2" : "subtitle1"}
          style={{ color: "white", textAlign: isMobile ? "center" : "center" }}
        >
          🍃 Handpicked Leaves | 🌿 Organically Sourced | 🔬 Lab-Tested Quality
        </Typography>
      </div>
      {/* Main banner */}
      <Container maxWidth="lg" style={{ padding: "40px" }}>
        {/* Product Feature Section */}
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={10} md={6}>
            <Card raised>
              <CardMedia
                component="img"
                image="https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/productImages%2Fpackage%20label%2FLabel%20Oldman%20Clover%20White.png?alt=media&token=84a2f4b1-a149-4bb4-89c3-b25704355167"
                // "https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/productImages%2Fpackage%20label%2FLabel%20Oldman%20Clover%20Green.png?alt=media&token=471057c8-17f6-4c19-b1df-11c17019e307" // Replace with the path to your image
                alt="Super Green Maeng Da"
                style={{
                  width: "100%", // ensures the image takes the full width of the card
                  height: "auto",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#388e3c",
                  }}
                >
                  {/* Super Green Maeng Da */}
                  White Maeng Da
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  style={{ textAlign: "center" }}
                >
                  $10.99 - $180
                </Typography>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#4caf50" }}
                    onClick={() => {
                      navigate("/shop/" + "white-maeng-da");
                    }}
                  >
                    Shop Now
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={10} md={6}>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                color: "white",
                marginBottom: "20px",
                textAlign: isMobile ? "center" : "end",
              }}
            >
              Unlock the Power of Nature with White Maeng Da
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "white",
                marginBottom: "10px",
                textAlign: isMobile ? "center" : "end",
              }}
            >
              Dive deep into the heart of nature with our White Maeng Da.
              Crafted meticulously, this product is more than just Kratom – it's
              a testimony to nature's incredible power and potential.
            </Typography>
            {/* ... Add more descriptive text or bullet points here ... */}
          </Grid>
        </Grid>
      </Container>
      {/* <FeatureSection/> */}
      {/* Exclusive Offer Section with Emphasis on Same-Day Delivery */}
      <div style={containerStyle}>
        <Typography
          variant="h4"
          style={{ color: "#2e7d32", marginBottom: "1rem" }}
        >
          Most Flexible Delivery Options in Canada
        </Typography>

        <div style={sectionsContainerStyle}>
          <div style={sectionStyle}>
            <Typography
              variant="h6"
              style={{ color: "#388e3c", marginBottom: "0.5rem" }}
            >
              Weekend Pickup Option
            </Typography>
            <Typography variant="body1">
              Available Saturday and Sunday. Pick up your Kratom at your
              convenience.
            </Typography>
          </div>

          <div style={sectionStyle}>
            <Typography
              variant="h6"
              style={{ color: "#388e3c", marginBottom: "0.5rem" }}
            >
              Free Delivery
            </Typography>
            <Typography variant="body1">
              Enjoy Kratom without the extra cost. Our Free Delivery service
              ensures you don’t pay more.
            </Typography>
          </div>

          <div style={sectionStyle}>
            <Typography
              variant="h6"
              style={{ color: "#388e3c", marginBottom: "0.5rem" }}
            >
              Express Shipping
            </Typography>
            <Typography variant="body1">
              Fast delivery when you need it. Free Express Shipping on orders
              over $100.
            </Typography>
          </div>

          <div style={sectionStyle}>
            <Typography
              variant="h6"
              style={{ color: "#388e3c", marginBottom: "0.5rem" }}
            >
              Same-Day Delivery in Toronto
            </Typography>
            <Typography variant="body1">
              Exclusive to downtown Toronto: benefit from same-day shipping,
              ensuring the fastest delivery directly to your door.
            </Typography>
          </div>
        </div>

        <Button
          variant="contained"
          style={{ backgroundColor: "#4caf50" }}
          onClick={() => {
            navigate("/shop");
          }}
          startIcon={<StorefrontIcon />}
        >
          Shop Local Selection
        </Button>
      </div>

      {/* Testimonials Section */}
      <Grid
        container
        spacing={3}
        style={{
          marginTop: "2rem",
          textAlign: "center",
          paddingInline: "1rem",
        }}
      >
        <Grid item xs={12}>
          <section style={{padding:'10px'}} className="testimonials-section">
            <h2 style={{ color: "white" }}>
              Hear From Our Satisfied Customers
            </h2>
            <Slider style={{ color: "white" }} {...settings}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <img
                    src="parth.webp"
                    alt="Testimonial Parth"
                    className="testimonial-image"
                  /> */}
                </div>
                <blockquote style={{ textAlign: "center" }}>
                  "I've tried various Kratom strains, but Super Green Maeng Da
                  is unbeatable. The focus and energy it provides are
                  unparalleled!"
                </blockquote>
                <p>- Parth P., Financial Analyst</p>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <img
                    src="lin.webp"
                    alt="Testimonial Lin"
                    className="testimonial-image"
                  /> */}
                </div>
                <blockquote style={blockquoteStyle}>
                  "As a freelance graphic designer, Super Green Maeng Da helps
                  me maintain my creative flow and meet tight deadlines."
                </blockquote>
                <p>- Lin K.</p>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <img
                    src="joslin.webp"
                    alt="Testimonial Joslin"
                    className="testimonial-image"
                  /> */}
                </div>
                <blockquote style={blockquoteStyle}>
                  "Being a university student, I use Super Green Maeng Da to
                  stay focused during late-night study sessions."
                </blockquote>
                <p>- Joslin U., College Student</p>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <img
                    src="chad.webp"
                    alt="Testimonial Chad"
                    className="testimonial-image"
                  /> */}
                </div>
                <blockquote style={blockquoteStyle}>
                  "Incorporating Super Green Maeng Da into my pre-workout
                  routine has boosted my stamina and focus during intense
                  sessions."
                </blockquote>
                <p>- Chad M., Fitness Trainer</p>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <img
                    src="marco.webp"
                    alt="Testimonial Marco"
                    className="testimonial-image"
                  /> */}
                </div>
                <blockquote style={blockquoteStyle}>
                  "Managing a tech startup can be stressful. Super Green Maeng
                  Da gives me the clarity and energy I need to lead my team."
                </blockquote>
                <p>- Marco M., Tech Entrepreneur</p>
              </div>
              {/* Additional slides can be added here... */}
            </Slider>
          </section>
        </Grid>
      </Grid>

      {/* Community Service Section */}
      <Container maxWidth="lg" style={{ padding: "40px" }}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ fontWeight: "bold", textAlign: "center", color: "white" }}
        >
          Proudly Serving the Toronto Community
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "center", color: "white" }}
        >
          At Mystics Leaf, we are dedicated to serving the wellness needs of
          Ontario. With same-day delivery in Toronto, we bring the best of
          nature right to your doorstep.
        </Typography>
        {/* <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LocalShippingIcon />}
            style={{ backgroundColor: '#4caf50' }}
            onClick={() => navigate('/delivery-info')}
          >
            Learn About Delivery
          </Button>
        </div> */}
      </Container>

      {/* Exclusive Offer Section */}
      <div
        className="exclusive-offer-section"
        style={{
          backgroundColor: "#e8f5e9",
          padding: "4rem",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{ color: "#2e7d32", marginBottom: "1rem" }}
        >
          Exclusive Offer Just for You!
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "2rem" }}>
          For a limited time, we're offering FREE 2-gram samples for new
          customers. Experience the power of Kratom.
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: "#4caf50" }}
          onClick={() => {
            navigate("/sampleForm");
          }}
        >
          Claim Your Free Sample Now
        </Button>
      </div>

      {/* Benefits Section */}
      <Container maxWidth="lg" style={{ padding: "4rem 1rem" }}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ textAlign: "center", color: "white" }}
        >
          Why Choose Our Super Green Maeng Da?
        </Typography>
        {/* Benefits Grid */}
        <Grid container spacing={3}>
          {/* Benefit 1 */}
          <Grid item xs={12} md={4} className="text-center">
            <img
              src={"organic.png"}
              alt={"Leaf"}
              style={{ maxWidth: "100px" }}
            />
            <Typography variant="h6" style={{ color: "white" }}>
              100% Organic
            </Typography>
            <Typography variant="body2" style={{ color: "white" }}>
              Pure and natural, without any additives. Experience nature's best
              with every dose.
            </Typography>
          </Grid>
          {/* Benefit 2 */}
          <Grid item xs={12} md={4} className="text-center">
            <img src={"lab.png"} alt={"Leaf"} style={{ maxWidth: "100px" }} />
            <Typography variant="h6" style={{ color: "white" }}>
              Lab-Tested for Purity
            </Typography>
            <Typography variant="body2" style={{ color: "white" }}>
              Ensuring safety and potency, every batch undergoes rigorous
              testing. Trust in quality with every purchase. You can view our
              lab reports .
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#4caf50", marginTop: "17px" }}
              onClick={() =>
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/assets%2FSupergreen_Resulsts.jpg?alt=media&token=d0f88ec1-2b23-4ffc-8d51-33de849c2900",
                  "_blank"
                )
              }
            >
              View Lab Results
            </Button>
          </Grid>
          {/* Benefit 3 */}
          <Grid item xs={12} md={4} className="text-center">
            <img
              src={"delivery.png"}
              alt={"Leaf"}
              style={{ maxWidth: "100px" }}
            />
            <Typography variant="h6" style={{ color: "white" }}>
              Quick & Secure Shipping
            </Typography>
            <Typography variant="body2" style={{ color: "white" }}>
              Promptly delivered to your doorstep. Shop with confidence, knowing
              your details are protected.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {/* 
      <Container
        maxWidth="lg"
        style={{
          padding: "2rem 0",
          backgroundColor: "#f1f8e9",
          marginBottom: "10px",
        }}
      >
      
        </Container> */}
    
      <BlogCarousel blogs={blogs} />
      

      {/* 'What is Kratom?' Section */}
      <Container
        maxWidth="lg"
        style={{
          padding: "2rem 0",
          backgroundColor: "#f1f8e9",
          marginBottom: "50px",
        }}
      >
        <Grid
          container
          spacing={4}
          style={{
            padding: "2rem 1.5rem",
            borderRadius: "10px",
            paddingLeft: "20px",
          }}
        >
          <Grid item xs={12} md={6}>
            {/* Kratom educational content */}
            <Typography
              variant="h5"
              style={{ marginBottom: "1rem", fontWeight: "bold" }}
            >
              What is Kratom?
            </Typography>
            <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
              Kratom, scientifically known as Mitragyna speciosa, is a tropical
              evergreen tree native to Southeast Asia, particularly in countries
              like Thailand, Indonesia, and Malaysia.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "10px" }}>
              The leaves of the Kratom tree have been used traditionally for
              centuries in these regions for their medicative properties. They
              are known to provide relief from chronic pain, enhance mood and
              energy, aid in opiate withdrawal, and promote relaxation.
            </Typography>
            <Typography variant="body1">
              Kratom comes in various strains, each offering a unique blend of
              benefits. Its potency and effects can vary based on the region
              it's sourced from and how it's processed. From uplifting moods to
              promoting a sense of well-being, Kratom has found its place in the
              hearts of many for its diverse range of advantages. At Mystics
              Leaf, we're committed to providing the purest form of Kratom,
              ensuring you experience its natural benefits in the most authentic
              manner.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Kratom image */}
            <CardMedia
              component="img"
              height="auto"
              image="https://www.kratomcountry.com/media/mageplaza/blog/post/6/5/65696431_m.jpg" // Replace with the path to your image
              alt="Kratom leaves"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        style={{ padding: "2rem 0", marginBottom: "25px" }}
        className="text-center"
      >
        {/* Introductory Text */}
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginBottom: "20px", color: "white" }}
        >
          Bringing You Kratom, Pure and True
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "center", marginBottom: "2rem", color: "white" }}
        >
          Our pledge is to offer kratom in its most natural form—pure, pristine,
          and unmodified. We believe you're entitled to the finest kratom
          products complemented by superior customer service. Our dedication is
          to fulfill this promise at every interaction.
        </Typography>
        <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#4caf50", marginTop: "17px" }}
              onClick={() =>
                navigate("/shop")
              }
            >
              BUY KRATOM ONLINE
            </Button>
      </Container>
    </div>
  );
}
