import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Navbar from "../shared/Navbar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Box } from "@mui/system";
import { logCustomEvent } from "../../services/analyticsLogger";
import "./Faq.css";
import { Helmet } from "react-helmet"; // You might need to install the react-helmet package

function FaqPage() {
  // const [toggleWigView, setToggleWigView] = useState(false)
  useEffect(() => {
    // logEvent(analytics, 'faq')
    // logEvent(analytics, 'screen_view', {
    //     firebase_screen: 'faq',
    // });
    const uid = sessionStorage.getItem("uid");
    logCustomEvent("screen_view", {
      firebase_screen: "faq",
      uid: uid,
    });
  }, []);

  const downtownTorontoFSAs = [
    "M5A", "M5B", "M5C", "M5E", "M5G", "M5H", "M5J", "M5K", "M5L", "M5S", "M5T", "M5V", "M5W", "M5X",
    "M6J", "M6K", "M6G", "M6H", "M6R", "M7A", "M4S", "M4V", "M4T", "M4W", "M4X", "M4Y", "M4M", "M4L",
    "M4K", "M7Y", "M4J", "M5N"
  ].sort();
  const faqPageStructuredData = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is Kratom?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Kratom, scientifically known as Mitragyna speciosa, is a tropical tree native to Southeast Asia, used traditionally for its medicinal properties.",
        },
      },
      {
        "@type": "Question",
        name: "Is Kratom Legal in Canada?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The legality of Kratom in Canada is subject to specific regulations. For more detailed information, please refer to our comprehensive guide on Kratom's legal status in Canada Visit our blog at [Mystics Leaf Blog](https://mysticsleaf.com/blog/kratom-in-the-gta-understanding-its-legal-status-and-where-to-find-it).",
        },
      },
      {
        "@type": "Question",
        name: "What are the typical effects at each dosage level for Green Kratom?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The effects of Green Kratom can vary between individuals, but the following is a general guide for dosage: Low dose (1-2 grams): Typically produces mild stimulation. Moderate dose (2-5 grams): A balance of stimulation and sedation. High dose (5-8 grams): More pronounced sedation and pain relief. Very high dose (8-15 grams): Strong sedative effects with a higher risk of side effects.",
        },
      },
      {
        "@type": "Question",
        name: "Can you buy Kratom in Canada?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes you can visit our shop at [Mystics Leaf](https://mysticsleaf.com/shop) to buy Kratom in Canada.",
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(faqPageStructuredData)}
        </script>
      </Helmet>
      <Navbar />

      <Box
        bgcolor="#5b954b"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow:
            "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
          paddingInline: "1rem",
        }}
        py={4}
      >
        <QuestionMarkIcon sx={{ color: "#e0e0e0", fontSize: "70px" }} />
        <Typography variant="h4" color="#e0e0e0" fontWeight="500" mt={2}>
          Frequently Asked Questions
        </Typography>
      </Box>
      <Divider style={{ padding: "30px" }}>
        <Chip color="primary" label="General" />
      </Divider>

      {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> */}
      <Grid
        container
        xs={12}
        padding={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <span>
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                Who is Mystics Leaf and what is our mission?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography paragraph>
                At the heart of Mystics Leaf is a reverence for the ancient and
                mystical herb known as Kratom, cherished for centuries across
                cultures for its unique and transformative properties. Our
                journey is one of discovery, aimed at uncovering the secrets of
                Kratom and sharing them with those who seek a holistic path to
                well-being.
              </Typography>
              <Typography paragraph>
                We pledge to deliver only the highest quality Kratom, sourced
                directly from the verdant and untamed jungles where this sacred
                botanical is nurtured. Our commitment extends beyond commerce;
                we endeavor to enlighten and educate our community about
                Kratom's traditional roots, ongoing research, and its myriad of
                potential uses.
              </Typography>
              <Typography className="typography">
                Mystics Leaf transcends being merely a brand — we are the
                custodians of Kratom's legacy, a testament to the mystical bond
                between nature and healing. Join us in this movement as we
                strive to harmonize the wonders of Kratom with the rhythm of
                modern life, enhancing wellness and guiding our customers
                towards a symphony of balance and serenity.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ: What is Kratom? */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="accordionSummary"
            >
              <Typography className="typography">What is Kratom?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                Kratom, also known as Mitragyna speciosa, is a tropical
                evergreen tree native to Southeast Asia. It has been used
                traditionally for medicinal purposes. The leaves contain
                compounds that can have psychotropic effects. Mystics Leaf
                offers Kratom for ethnobotanical and research purposes only.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ: How should Kratom be used? */}
          {/* <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            className="accordionSummary"
                        >
                            <Typography className="typography">How should Kratom be used?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                Kratom can be consumed in several ways, including powder, capsules, or extracts. The method of consumption and dosage should be determined based on individual research and understanding of Kratom, within the legal bounds and guidelines provided by local regulations in Canada.
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                Where Can I See Your Lab Test?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                You can take a look at our lab result here:{" "}
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/assets%2FSupergreen_Resulsts.jpg?alt=media&token=d0f88ec1-2b23-4ffc-8d51-33de849c2900"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Super Green
                </a>
                ,
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/mystics-leaf.appspot.com/o/assets%2FRed_Resulsts.jpg?alt=media&token=a803b7f5-d8a2-44b0-85d5-b05195a67dbf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Red
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                Is the packaging discreet with no mention of "kratom"?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                Mystics Leaf values your privacy and takes measures to ensure
                confidentiality. We dispatch our products in plain packaging,
                devoid of any reference to "kratom" or branding from Mystics
                Leaf. This guarantees that the contents remain private, offering
                you a discreet shipping experience.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                Why is Kratom labeled as "Not for Human Consumption"?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                Due to the absence of Canadian regulations governing Kratom, its
                sale is permissible only when not presented for consumption.
                This labeling ensures legal compliance, disassociating the
                product from consumption and governmental endorsement of safety
                or use.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="accordion">
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
    className="accordionSummary"
  >
    <Typography className="typography">
      What shipping options are available?
    </Typography>
  </AccordionSummary>
  <AccordionDetails>
  <Typography className="typography">
    We offer four shipping options to cater to your needs:
    <ol>
      <li>
        <strong>Pickup:</strong> Collect your order from our driver at Downtown Toronto City Hall.
        Pickup is available on Saturdays and Sundays from 1 PM to 7 PM. Please select your preferred
        pickup time during checkout. Orders must be placed before <strong style={{ color: 'red' }}>2 PM</strong> for same-day pickup. Our driver will contact you upon arrival to coordinate the exact
        location for the handoff. <em>Note:</em> If you are late or fail to show up, you may be restricted
        from using the pickup option in the future.
      </li>
      <li>
        <strong>Mail:</strong> Standard mail service, with delivery times depending on your location.
        Orders placed before <strong style={{ color: 'red' }}>2 PM</strong> are shipped the same day.
      </li>
      <li>
        <strong>Same Day Delivery:</strong> Available exclusively for select postal codes in downtown Toronto (
        {downtownTorontoFSAs.join(", ")}). This option ensures your order is delivered on the same day it's placed.
        Orders must be placed before <strong style={{ color: 'red' }}>2 PM</strong> for same-day delivery.
      </li>
      <li>
        <strong>Express Shipping:</strong> For when you need your order delivered as quickly as possible.
        Express shipping ensures your package arrives swiftly. Orders placed before <strong style={{ color: 'red' }}>2 PM</strong> are shipped the same day.
      </li>
    </ol>
    <Typography variant="body2" style={{ marginTop: '10px' }}>
      <em>Please note:</em> Orders placed after <strong style={{ color: 'red' }}>2 PM</strong> will be processed the next business day.
    </Typography>
    Please choose the option that best suits your needs during the checkout process.
  </Typography>
</AccordionDetails>

</Accordion>


          {/* FAQ: Is Kratom legal in Canada? */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                Is Kratom legal in Canada?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                Kratom is legal to purchase and use in Canada, but it cannot be
                sold with the intent for it to be ingested. Buyers should verify
                the legal status in their local area as regulations can vary.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ: Can Kratom be detected in a drug test? */}
          {/* <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                Can Kratom be detected in a drug test?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                Standard drug tests do not typically test for Kratom. However,
                specific Kratom alkaloids can be detected by certain drug tests
                designed to identify Mitragynine and 7-hydroxymitragynine.
              </Typography>
            </AccordionDetails>
          </Accordion> */}

          {/* FAQ: What are the effects of Kratom? */}
          {/* <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5-content"
              id="panel5-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                What are the effects of Kratom?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                The effects of Kratom vary widely depending on the strain,
                dosage, and individual physiology. Effects can range from
                increased energy and focus to sedation and pain relief. It is
                essential to research and understand Kratom before use.
              </Typography>
            </AccordionDetails>
          </Accordion> */}

          {/* FAQ: How do I purchase Kratom from Mystics Leaf? */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6-content"
              id="panel6-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                How do I purchase Kratom from Mystics Leaf?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                You can purchase Kratom from Mystics Leaf by browsing our
                selection online, choosing the desired product and quantity, and
                proceeding to checkout. We offer various payment methods for
                your convenience.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* FAQ: How is Kratom shipped and how long does it take to arrive? */}
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7-content"
              id="panel7-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                How is Kratom shipped and how long does it take to arrive?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                Mystics Leaf ships Kratom discreetly in compliance with Canadian
                regulations. Shipping times vary based on location and shipping
                method chosen. We offer several options to meet your needs.
              </Typography>
            </AccordionDetails>
          </Accordion>


          {/* Affects of Kratom */}
          {/* <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="accordionSummary"
            >
              <Typography className="typography">
                What are the typical effects at each dosage level for Green
                Kratom?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="typography">
                The effects of Green Kratom can vary between individuals, but
                the following is a general guide for dosage:
                <ul>
                  <li>
                    <strong>Low dose (1-2 grams / 0.4-0.8 teaspoons):</strong>{" "}
                    Typically produces mild stimulation with increased energy
                    and focus.
                  </li>
                  <li>
                    <strong>
                      Moderate dose (2-5 grams / 0.8-2 teaspoons):
                    </strong>{" "}
                    A balance of stimulation and sedation, possibly with mild
                    euphoria and relaxation.
                  </li>
                  <li>
                    <strong>High dose (5-8 grams / 2-3.2 teaspoons):</strong>{" "}
                    More pronounced sedation and pain relief, with potential
                    impairment of motor skills.
                  </li>
                  <li>
                    <strong>
                      Very high dose (8-15 grams / 3.2-6 teaspoons):
                    </strong>{" "}
                    Strong sedative effects and significant pain relief, with a
                    higher risk of side effects such as nausea or dizziness.
                  </li>
                </ul>
                Please start with the lowest dose to assess tolerance and never
                exceed the recommended dosage. The use of a scale for precise
                measurement is encouraged.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </span>
      </Grid>

      {/* <Divider style={{ padding: '30px' }} >
                <Chip color="primary" label="Selling Used Wigs" />
            </Divider>

            <Grid container xs={12} padding={3} display="flex" justifyContent="center" alignItems="center">
                <span>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="typography">Can I sell my wig used ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                Yes you can sell your wigs that have been used, but must in good condition.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">How much can I expect from selling my wig?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                It depends on the wig condition and quality of the wig, and how much you paid for the wig. We will let you know the value of your wig with our verification methods before shipping.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">What commission fee do I pay when selling a used wig ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                For used wigs, a 20% fee is levied. In the case of Wig Makers selling their own wigs and managing shipping, the charge will be 5%.
                            </Typography>
                            <Typography className="typography"> If you are selling a used wigs on our platform, a we take a commission fee of 20% . </Typography>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">Do I pay for shipping?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                No! if your wig or hair piece meets our standards we will send you shipping stickers to ship to our facilities.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">Why must the wigs be shipped to Hair Again?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                We must make sure all wigs are clean and sterilized before shipping to our clients, we also take photos product photos at our studio before listing.
                                Hair Again prides itself on quailty and we want to make sure all our clients are satisfied.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </span>

            </Grid>

            <Divider style={{ padding: '30px' }}>
                <Chip color="primary" label="For Wig Makers" />
            </Divider>


            <Grid container xs={12} padding={3} mb={10} display="flex" justifyContent="center" alignItems="center">
                <span>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="typography">Do I have to provide a refund if a buyer is not satisfied with their purchase?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                As a wig maker, you are not required to provide a refund if a buyer is not satisfied with their purchase. However, we encourage you to work with the buyer to resolve any issues and maintain good customer relations.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">What types of wigs can I sell on your platform?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                You can sell any type of wig on our platform as long as it meets our requirements for condition, cleanliness, and accuracy in description.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">How do I handle returns?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                As a wig maker, you are not required to accept returns. However, we encourage you to work with the buyer to resolve any issues and maintain good customer relations.
                            </Typography>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">Can I sell wigs that are not made by me?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                No, our platform is only for wig makers selling their own wigs. If you are interested in selling wigs that are not made by you, please contact us to discuss other options.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">How much commission do I pay when selling my own wigs as a wig maker?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                As a wig maker selling your own wigs and handling shipping yourself, you pay a commission fee of 5%.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="typography">How do I know when my wig has been sold?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="typography">
                                You will receive a notification when your wig has been sold, and we will provide you with the shipping address of the buyer.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </span>

            </Grid> */}
    </>
    //  </Box>
  );
}

export default FaqPage;
