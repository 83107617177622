import React, { useState } from 'react';
import { Button, ButtonGroup, Divider } from "@mui/material";
import Referrals from './Referrals'; // Make sure to create this component
import EarnPoints from './EarnPoints'; // Make sure to create this component
import MyRewards from './MyRewards'; // Make sure to create this component
import "./Rewards.css";

const Rewards = ({ userData }) => {
  const [selectedSection, setSelectedSection] = useState('myRewards'); // Set default to 'myRewards'

  return (
    <div className="rewards-wrapper">
      <ButtonGroup variant="outlined" color="primary" className="rewards-nav" fullWidth>
        <Button 
          variant={selectedSection === 'myRewards' ? "contained" : "outlined"}
          onClick={() => setSelectedSection('myRewards')}
        >
          My Rewards
        </Button>
        <Button 
          variant={selectedSection === 'referrals' ? "contained" : "outlined"}
          onClick={() => setSelectedSection('referrals')}
        >
          Referrals
        </Button>
        {/* <Button 
          variant={selectedSection === 'earnPoints' ? "contained" : "outlined"}
          onClick={() => setSelectedSection('earnPoints')}
        >
          Earn Points
        </Button> */}
      </ButtonGroup>
      <Divider style={{ margin: '20px 0' }} />
      <div className="rewards-content">
        {selectedSection === 'myRewards' && <MyRewards userData={userData} />}
        {selectedSection === 'referrals' && <Referrals userData={userData} />}
        {selectedSection === 'earnPoints' && <EarnPoints userData={userData} />}
      </div>
    </div>
  );
};

export default Rewards;
