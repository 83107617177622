import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap"; // Assuming you're using reactstrap
import moment from "moment";
import "./ordersummary.css";

// const shippingOptions = {
//   freeSameDayDowntown: {
//     label:
//       "$15 Same Day Toronto Downtown Delivery (must be ordered before 2pm)",
//     price: 15,
//   },
//   expressDelivery: {
//     label: "Express (1-2 Business Days)",
//     price: 10.0,
//   },
//   freeShipping: {
//     label: "Mail (3-7 Business Days)",
//     price: 0,
//   },
//   orderPickup: {
//     label: "Pick-up (Same Day 5pm-7pm)(Near Eaton Centre or Union Station)(Free)",
//     price: 0,
//   },
// };

function OrderModal({ order, isOpen, onClose }) {
  const subtotal = order.cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  const shippingOptions = {
    freeSameDayDowntown: {
      label:
        "$15 Same Day Toronto Downtown Delivery (must be ordered before 2pm)",
      price: 15,
    },
    expressDelivery: {
      label: "Express (1-2 Business Days)",
      get price() {
        // This function will check if the total is above $99 and return 0 if so
        return subtotal > 99 ? 0 : 10.0;
      },
    },
    freeShipping: {
      label: "Mail (3-7 Business Days)(Free)",
      price: 0,
    },
    orderPickup: {
      label:
        "Pick-up (Same Day 5pm-7pm)(Near Eaton Centre or Union Station)(Free)",
      price: 0,
    },
  };

  const shippingLabel =
    shippingOptions[order.shippingMethod]?.label || "Unknown Shipping Method";
  const shippingPrice = shippingOptions[order.shippingMethod]?.price || 0;

  return (
    <>
      {order && (
        <Modal isOpen={isOpen} toggle={onClose} className="order-modal">
          <ModalHeader toggle={onClose}>
            Order Details for {order.firstName} {order.lastName}
          </ModalHeader>
          <ModalBody>
            <p>
              <strong>Placed on:</strong>{" "}
              {moment.unix(order.unixTime).format("dddd, MMMM Do YYYY")}
            </p>
            <p>
              <strong>Status:</strong> {order.status}
            </p>
            <p>
              <strong>Payment Status:</strong> {order.paid ? "Paid" : "Pending"}
            </p>
            {order.notes && (
              <p>
                <strong>Notes:</strong> {order.notes}
              </p>
            )}

            <p>
              <strong>Shipping:</strong> {shippingLabel}
            </p>
            <p>
              <strong>Delivery Address:</strong>{" "}
              {`${order.address1}${
                order.address2 ? `, ${order.address2}` : ""
              }, ${order.city}, ${order.province}, ${order.postalCode}, ${
                order.country
              }`}
            </p>
            {order.thirdPartyTrackingNumber && (
              <p>
                <strong>Tracking Number:</strong>{" "}
                <a
                  href={`https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor=${order.thirdPartyTrackingNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.thirdPartyTrackingNumber}
                </a>
              </p>
            )}
            <ListGroup>
              {order.cartItems.map((item, index) => (
                <ListGroupItem
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <h5 className="mb-1">{item.name}</h5>
                    <p className="mb-1">Quantity: {item.quantity}</p>
                    <small>Price: ${item.price.toFixed(2)}</small>
                  </div>
                  <img
                    src={item.productImage}
                    alt={item.name}
                    className="img-thumbnail"
                    style={{ maxWidth: "100px" }}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <strong>Subtotal:</strong>
              <span>${subtotal.toFixed(2)}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
              <strong>Tax:</strong>
              <span>${order?.tax ? order?.tax : 0}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <strong>Shipping Charge:</strong>
              <span>${shippingPrice.toFixed(2)}</span>
            </div>
            {order.discountSelected && order.discountAmount > 0 && (
              <div className="d-flex justify-content-between align-items-center mt-3">
                <strong>Discount Applied:</strong>
                <span>
                  -${order.discountAmount.toFixed(2)} (
                  {order.discountSelected.code})
                </span>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <strong>Total:</strong>
              <span>${order.grandTotal ? order.grandTotal : 0}</span>
            </div>

            <hr />
            <div className="updated-last">
              <p>
                <strong>Last Updated:</strong>{" "}
                {moment.unix(order.updatedLast).format("dddd, MMMM Do YYYY")}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default OrderModal;
